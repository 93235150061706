import React, { cloneElement, createContext, useEffect, useState } from 'react';
import { Switch, useHistory, useLocation, Route } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

//Custom Css
import '../../../../../EnrollmentStyles/planHelpTextStyles.css';

//Assets

//Muicons

//MuiComponents
import Box from '@mui/material/Box';
import { useTheme, useMediaQuery } from '@mui/material';
//Services
import memberService from '../../../../../../services/member-service';
import enrollmentService from '../../../../../../services/enrollment-service';
import organizationService from '../../../../../../services/organization-service';
import calculatorService from '../../../../../../services/calculator-service';
import optionService from '../../../../../../services/optionService';

//Our Components
import ExternalEnrollmentCalculator from '../../../../../app-external/external-enrollments/ExternalEnrollmentCalculator';
import EnrollmentPlans from '../../../../../app-external/external-enrollments/enrollment-plans/EnrollmentPlans';
import EnrollmentBeneficiaries from '../../../../../app-external/external-enrollments/enrollment-beneficiaries/EnrollmentBeneficiaries';
import EnrollmentPayment from '../../../../../app-external/external-enrollments/enrollment-plans/payment-components/payScheduleAndBank-components/EnrollmentPayment';
import EnrollmentPersonalInfo from '../../../../../app-external/external-enrollments/enrollment-personal-info/EnrollmentPersonalInfo';
import StickyProgressTotal from '../../../../../app-external/external-enrollments/StickyProgressTotal';
import EnrollmentComplete from '../../../../../app-external/external-enrollments/enrollment-complete/EnrollmentComplete';
import PoweredBy from '../../../../../app-external/external-enrollments/PoweredBy';
import InfinityLoader from '../../../../../UH-loading-animation/InfinityLoader';
import EnrollmentProgressSaved from '../../../../../app-external/external-enrollments/progress-saved/EnrollmentProgressSaved';
import AgentNavigation from './AgentNavigation';

//utils
import {
  checkMobileDevice,
  userTimeZone,
  formatUTCDateToObjWithTimeZone,
} from '../../../../../utils';

export let enrollmentContext = createContext();

export default function AgentEnrollmentWrapper(props) {
  const isMobileDevice = checkMobileDevice();
  const history = useHistory();
  const location = useLocation();
  let route = location.pathname;

  const [loading, setLoading] = useState(false);

  const [fullMemberObj, setFullMemberObj] = useState({});
  const [userInfo, setUserInfo] = useState({
    GroupPlanId: null,
    Salary: '',
    dob: '',
    OrganizationId: null,
    spouseDOB: null,
  });

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  const getThisMember = async () => {
    setLoading(true);
    // let thisMember = await getFullMemberObj()
    let thisMember = props?.member;

    // let subgroupsResults = await calculatorService.getSubGroups()  //POTENTIAL ISSUE HERE WITH SUBGROUPS, LOOK AT MEMBERPROFILE
    // console.log('sub groups', subgroupsResults)
    let groupPlanId = null;
    // let organizationId = null;
    //If there's only one choice, then that's our id
    // if (subgroupsResults.groupPlans.length == 1) groupPlanId = subgroupsResults.groupPlans[0].id
    // if (subgroupsResults.organizations.length == 1) organizationId = subgroupsResults.organizations[0].id
    groupPlanId = props?.singleEnrollment?.groupPlanId;

    //format dob from db debending on device
    let formattedDob = null;
    if (thisMember?.dob) {
      //format dob w/ timezone
      const dobWithTimezone = formatUTCDateToObjWithTimeZone(thisMember.dob);
      //format further for device
      // if(isMobileDevice){
      //   formattedDob = dobWithTimezone //MUI datepicker format
      // }else{
      //   formattedDob = format(new Date(dobWithTimezone), "yyyy-MM-dd"); //MUI textfield format
      // }
      formattedDob = dobWithTimezone;
    }
    if (thisMember?.returnToWork) {
      thisMember.returnToWork = formatUTCDateToObjWithTimeZone(
        thisMember.returnToWork
      );
    }

    let _userInfo = {
      dob: formattedDob,
      Salary: thisMember.annualSalary,
      GroupPlanId: groupPlanId,
      OrganizationId: thisMember.organizationId,
      spouseDOB: spouse?.dateOfBirth,
    };

    setUserInfo(_userInfo);

    let _userInfoCalc = {
      dob: thisMember?.dob,
      Salary: thisMember.annualSalary,
      GroupPlanId: groupPlanId,
      OrganizationId: thisMember.organizationId,
      spouseDOB: spouse?.dateOfBirth,
    };

    let _results;
    try {
      _results = await calculatorService.updateMemberCalculateRatesForMember(
        thisMember.id,
        _userInfoCalc
      );
    } catch (error) {
      console.log('error calculating rates', error);
    }
    setResults(_results);
    getFullSocial(thisMember.id);
    setLoading(false);
  };

  useEffect(() => {
    if (props?.member) {
      setFullMemberObj(props?.member);
      getThisMember();
    }
  }, [props?.member]);

  //CALCULATION LOGIC

  //Plan info displayed to user
  const [results, setResults] = useState(null);
  //Set selections when we have results
  const [openEnrollment, setOpenEnrollment] = useState(false);
  const [isEnrolled, setIsEnrolled] = useState(false);
  //state to handle member + spouse, or member + dependents/family error handling info
  const [accidentSelection, setAccidentSelection] = useState(null);
  const [cancerSelection, setCancerSelection] = useState(null);
  const [addSelection, setAddSelection] = useState(null);
  const [hospitalSelection, setHospitalSelection] = useState(null);

  useEffect(() => {
    console.log('results ', results);
    if (results) {
      let builtSelections = {
        std: undefined, //optionId
        stdVal: 0, //PremiumAmount
        ltd: undefined, //optionId
        ltdVal: 0, //PremiumAmount
        ladd: [0, 0, 0], // Coverage Amounts
        laddVal: [0, 0, 0], //Premium amounts
        laddId: undefined, //planId
        accident: undefined,
        accidentVal: 0,
        cancer: undefined,
        cancerVal: 0,
        add: undefined,
        addVal: 0,
        hospital: undefined,
        hospitalVal: 0,
      };

      let builtLocalBennyRequiredPlans = {
        std: false,
        ltd: false,
        ladd: false,
        accident: false,
        cancer: false,
        add: false,
        hospital: false,
      };

      //std
      if (results?.stdPlan && results.stdPlan.selectedOptionId > 0) {
        builtSelections.std = results.stdPlan.selectedOptionId;
        builtSelections.stdVal = results.stdPlan.options.find(
          (option) => option.memberOptionId == builtSelections.std
        )?.memberPremiumAmount;

        //check for incremental plan and decline only option - if so, preselect declined & 0 amount, even if previous plan selected was not declined, decline is only option now due to salary change
        if (
          results?.stdPlan?.isIncremental &&
          results?.stdPlan?.options?.length === 1 &&
          results?.stdPlan?.options[0]?.isDecline
        ) {
          builtSelections.std = results?.stdPlan?.options[0]?.memberOptionId;
          builtSelections.stdVal = 0;
        }
        //if stdVal = 0, decline is already stored in db
        if (!builtSelections.stdVal && builtSelections.stdVal !== 0) {
          builtSelections.std = undefined;
          builtSelections.stdVal = 0;
        }
        if (builtSelections.stdVal > 0) {
          builtLocalBennyRequiredPlans.std =
            results?.stdPlan.isBeneficiaryEnabled;
        }

        //check for incremental plan and decline only option w/ non results saved in db yet - if so, preselect at declined & 0 amount
      } else if (
        results?.stdPlan?.isIncremental &&
        results?.stdPlan?.options?.length === 1 &&
        results?.stdPlan?.options[0]?.isDecline
      ) {
        builtSelections.std = results?.stdPlan?.options[0]?.memberOptionId;
        builtSelections.stdVal = 0;
      }

      //ltd
      if (results?.ltdPlan && results?.ltdPlan.selectedOptionId > 0) {
        builtSelections.ltd = results?.ltdPlan.selectedOptionId;
        builtSelections.ltdVal = results?.ltdPlan.options.find(
          (option) => option.memberOptionId == builtSelections.ltd
        )?.memberPremiumAmount;

        //check for incremental plan and decline only option - if so, preselect declined & 0 amount, even if previous plan selected was not declined, decline is only option now due to salary change
        if (
          results?.ltdPlan?.isIncremental &&
          results?.ltdPlan?.options?.length === 1 &&
          results?.ltdPlan?.options[0]?.isDecline
        ) {
          builtSelections.ltd = results?.ltdPlan?.options[0]?.memberOptionId;
          builtSelections.ltdVal = 0;
        }
        //if ltdVal = 0, decline is already stored in db
        if (!builtSelections.ltdVal && builtSelections.ltdVal !== 0) {
          builtSelections.ltd = undefined;
          builtSelections.ltdVal = 0;
        }
        if (builtSelections.ltdVal > 0) {
          builtLocalBennyRequiredPlans.ltd =
            results?.ltdPlan.isBeneficiaryEnabled;
        }
      } else if (
        results?.ltdPlan?.isIncremental &&
        results?.ltdPlan?.options?.length === 1 &&
        results?.ltdPlan?.options[0]?.isDecline
      ) {
        builtSelections.ltd = results?.ltdPlan?.options[0]?.memberOptionId;
        builtSelections.ltdVal = 0;
      }

      //ladd
      if (results?.lifeADDPlan) {
        builtSelections.laddId = results.lifeADDPlan.planId;
        if (results.lifeADDPlan.memberOptionsAreEnabled) {
          let selectedOption = results.lifeADDPlan.memberOptions.find(
            (option) => option.isSelected
          );
          if (selectedOption) {
            builtSelections.ladd[0] = selectedOption.coverageAmount;
            builtSelections.laddVal[0] = selectedOption.premiumAmount;
          }
        }

        if (results.lifeADDPlan.memberAndSpouseOptionsAreEnabled) {
          let selectedOption = results.lifeADDPlan.spouseOptions.find(
            (option) => option.isSelected
          );
          if (selectedOption) {
            builtSelections.ladd[1] = selectedOption.coverageAmount;
            builtSelections.laddVal[1] = selectedOption.premiumAmount;
          }
        }

        if (results.lifeADDPlan.memberAndDependentsAreEnabled) {
          let selectedOption = results.lifeADDPlan.dependentOptions.find(
            (option) => option.isSelected
          );
          if (selectedOption) {
            builtSelections.ladd[2] = selectedOption.coverageAmount;
            builtSelections.laddVal[2] = selectedOption.premiumAmount;
          }
        }
        if (builtSelections.laddVal[0] > 0)
          builtLocalBennyRequiredPlans.ladd =
            results.lifeADDPlan.isBeneficiaryEnabled;
      }
      if (results.isEnrollmentOpen) {
        setOpenEnrollment(true);
      } else {
        setOpenEnrollment(false);
      }
      if (results.isEnrolled) {
        setIsEnrolled(true);
      } else {
        setIsEnrolled(false);
      }

      //accident
      if (results?.accidentPlan && results.accidentPlan.selectedOptionId > 0) {
        builtSelections.accident = results.accidentPlan.selectedOptionId;
        if (results.accidentPlan.memberIsSelected) {
          let selectedOption = results.accidentPlan.options.find(
            (option) => option.memberOptionId == builtSelections.accident
          );
          builtSelections.accidentVal = selectedOption.memberPremiumAmount;
        } else if (results.accidentPlan.memberAndSpouseIsSelected) {
          let selectedOption = results.accidentPlan.options.find(
            (option) =>
              option.memberAndSpouseOptionId == builtSelections.accident
          );
          builtSelections.accidentVal =
            selectedOption.memberAndSpousePremiumAmount;
        } else if (results.accidentPlan.memberAndDependentsIsSelected) {
          let selectedOption = results.accidentPlan.options.find(
            (option) =>
              option.memberAndDependentsOptionId == builtSelections.accident
          );
          builtSelections.accidentVal =
            selectedOption.memberAndDependentsPremiumAmount;
        } else if (results.accidentPlan.familyIsSelected) {
          let selectedOption = results.accidentPlan.options.find(
            (option) => option.familyOptionId == builtSelections.accident
          );
          builtSelections.accidentVal = selectedOption.familyPremiumAmount;
        }
        if (builtSelections.accidentVal > 0)
          builtLocalBennyRequiredPlans.accident =
            results.accidentPlan.isBeneficiaryEnabled;
      }

      //cancer
      if (results?.cancerPlan && results.cancerPlan.selectedOptionId > 0) {
        builtSelections.cancer = results.cancerPlan.selectedOptionId;
        if (results.cancerPlan.memberIsSelected) {
          let selectedOption = results.cancerPlan.options.find(
            (option) => option.memberOptionId == builtSelections.cancer
          );
          builtSelections.cancerVal = selectedOption.memberPremiumAmount;
        } else if (results.cancerPlan.memberAndSpouseIsSelected) {
          let selectedOption = results.cancerPlan.options.find(
            (option) => option.memberAndSpouseOptionId == builtSelections.cancer
          );
          builtSelections.cancerVal =
            selectedOption.memberAndSpousePremiumAmount;
        } else if (results.cancerPlan.memberAndDependentsIsSelected) {
          let selectedOption = results.cancerPlan.options.find(
            (option) =>
              option.memberAndDependentsOptionId == builtSelections.cancer
          );
          builtSelections.cancerVal =
            selectedOption.memberAndDependentsPremiumAmount;
        } else if (results.cancerPlan.familyIsSelected) {
          let selectedOption = results.cancerPlan.options.find(
            (option) => option.familyOptionId == builtSelections.cancer
          );
          builtSelections.cancerVal = selectedOption.familyPremiumAmount;
        }
        if (builtSelections.cancerVal > 0)
          builtLocalBennyRequiredPlans.cancer =
            results.cancerPlan.isBeneficiaryEnabled;
      }

      //add
      if (results?.addPlan && results.addPlan.selectedOptionId > 0) {
        builtSelections.add = results.addPlan.selectedOptionId;
        if (results.addPlan.memberIsSelected) {
          let selectedOption = results.addPlan.options.find(
            (option) => option.memberOptionId == builtSelections.add
          );
          builtSelections.addVal = selectedOption.memberPremiumAmount;
        } else if (results.addPlan.memberAndSpouseIsSelected) {
          let selectedOption = results.addPlan.options.find(
            (option) => option.memberAndSpouseOptionId == builtSelections.add
          );
          builtSelections.addVal = selectedOption.memberAndSpousePremiumAmount;
        } else if (results.addPlan.memberAndDependentsIsSelected) {
          let selectedOption = results.addPlan.options.find(
            (option) =>
              option.memberAndDependentsOptionId == builtSelections.add
          );
          builtSelections.addVal =
            selectedOption.memberAndDependentsPremiumAmount;
        } else if (results.addPlan.familyIsSelected) {
          let selectedOption = results.addPlan.options.find(
            (option) => option.familyOptionId == builtSelections.add
          );
          builtSelections.addVal = selectedOption.familyPremiumAmount;
        }
        if (builtSelections.addVal > 0)
          builtLocalBennyRequiredPlans.add =
            results.addPlan.isBeneficiaryEnabled;
      }

      //hospital
      if (results?.hospitalPlan && results.hospitalPlan.selectedOptionId > 0) {
        builtSelections.hospital = results.hospitalPlan.selectedOptionId;
        if (results.hospitalPlan.memberIsSelected) {
          let selectedOption = results.hospitalPlan.options.find(
            (option) => option.memberOptionId == builtSelections.hospital
          );
          builtSelections.hospitalVal = selectedOption.memberPremiumAmount;
        } else if (results.hospitalPlan.memberAndSpouseIsSelected) {
          let selectedOption = results.hospitalPlan.options.find(
            (option) =>
              option.memberAndSpouseOptionId == builtSelections.hospital
          );
          builtSelections.hospitalVal =
            selectedOption.memberAndSpousePremiumAmount;
        } else if (results.hospitalPlan.memberAndDependentsIsSelected) {
          let selectedOption = results.hospitalPlan.options.find(
            (option) =>
              option.memberAndDependentsOptionId == builtSelections.hospital
          );
          builtSelections.hospitalVal =
            selectedOption.memberAndDependentsPremiumAmount;
        } else if (results.hospitalPlan.familyIsSelected) {
          let selectedOption = results.hospitalPlan.options.find(
            (option) => option.familyOptionId == builtSelections.hospital
          );
          builtSelections.hospitalVal = selectedOption.familyPremiumAmount;
        }
        if (builtSelections.hospitalVal > 0)
          builtLocalBennyRequiredPlans.hospital =
            results.hospitalPlan.isBeneficiaryEnabled;
      }

      setSelections(builtSelections);
      setLocalBennyRequiredPlans(builtLocalBennyRequiredPlans);
    }

    //Accident plan initial plan selected - logic for error handlling only
    let _accidentPlanSelection = results?.accidentPlan?.options.map(
      (option) => {
        if (selections.accident === option.memberOptionId) return 'member';
        else if (selections.accident === option.memberAndSpouseOptionId)
          return 'member and spouse';
        else if (selections.accident === option.memberAndDependentsOptionId)
          return 'member and dependents';
        else if (selections.accident === option.familyOptionId) return 'family';
        else return null;
      }
    );

    setAccidentSelection(_accidentPlanSelection);

    let _cancerPlanSelection = results?.cancerPlan?.options.map((option) => {
      if (selections.cancer === option.memberOptionId) return 'member';
      else if (selections.cancer === option.memberAndSpouseOptionId)
        return 'member and spouse';
      else if (selections.cancer === option.memberAndDependentsOptionId)
        return 'member and dependents';
      else if (selections.cancer === option.familyOptionId) {
        let onlyFamilyAndMemberAvailable =
          !option.memberAndDependentsAreEnabled &&
          !option.memberAndSpouseOptionsAreEnabled &&
          option.familyOptionsAreEnabled &&
          option.memberOptionsAreEnabled;
        if (onlyFamilyAndMemberAvailable) return 'familyOptional';
        else return 'family';
      }
    });

    setCancerSelection(_cancerPlanSelection);

    let _addPlanSelection = results?.addPlan?.options.map((option) => {
      if (selections.add === option.memberOptionId) return 'member';
      else if (selections.add === option.memberAndSpouseOptionId)
        return 'member and spouse';
      else if (selections.add === option.memberAndDependentsOptionId)
        return 'member and dependents';
      else if (selections.add === option.familyOptionId) {
        let onlyFamilyAndMemberAvailable =
          !option.memberAndDependentsAreEnabled &&
          !option.memberAndSpouseOptionsAreEnabled &&
          option.familyOptionsAreEnabled &&
          option.memberOptionsAreEnabled;
        if (onlyFamilyAndMemberAvailable) return 'familyOptional';
        else return 'family';
      } else return null;
    });

    setAddSelection(_addPlanSelection);

    let _hospitalPlanSelection = results?.hospitalPlan?.options.map(
      (option) => {
        if (selections.hospital === option.memberOptionId) return 'member';
        else if (selections.hospital === option.memberAndSpouseOptionId)
          return 'member and spouse';
        else if (selections.hospital === option.memberAndDependentsOptionId)
          return 'member and dependents';
        else if (selections.hospital === option.familyOptionId) {
          let onlyFamilyAndMemberAvailable =
            !option.memberAndDependentsAreEnabled &&
            !option.memberAndSpouseOptionsAreEnabled &&
            option.familyOptionsAreEnabled &&
            option.memberOptionsAreEnabled;
          if (onlyFamilyAndMemberAvailable) return 'familyOptional';
          else return 'family';
        } else return null;
      }
    );

    setHospitalSelection(_hospitalPlanSelection);
  }, [results]);

  //DO WE WANT TO PUSH THE AGENT ANYWHERE?
  // useEffect(()=> {
  //    if (results && !openEnrollment){
  //       history.push('/dashboard')

  //    }
  // },[openEnrollment])

  useEffect(() => {
    if (results && isEnrolled) {
      setAgentEnrollmentPageState({ complete: true });
    }
  }, [isEnrolled]);

  const updateResults = async () => {
    if (fullMemberObj?.dob) {
      let _userInfoCalc = {
        ...userInfo,
        dob: fullMemberObj.dob,
        spouseDOB: spouse?.dateOfBirth,
        OrganizationId: fullMemberObj?.organizationId,
      };
      // console.log('user info in agent enroller updateResults',_userInfoCalc)
      let _thisEnrollment;
      try {
        _thisEnrollment =
          await calculatorService.updateMemberCalculateRatesForMember(
            fullMemberObj.id,
            _userInfoCalc
          );
      } catch (error) {}
      // console.log(_thisEnrollment)
      setResults(_thisEnrollment);
    }
  };

  // Checking requirements for 4 or 9 SSN

  const [require9DigitSocial, setRequire9DigitSocial] = useState(false);
  const [fullSocial, setFullSocial] = useState();

  const checkRequireSocial = () => {
    let plans = [];
    for (let [key, value] of Object.entries(results)) {
      if (key.slice(key.length - 4, key.length) === 'Plan' && value != null) {
        plans.push(value);
      }
    }
    let _require9DigitSocial = false;
    for (let plan of plans) {
      //check for user selection (decline or otherwise)
      //ladd doesnt have selectedOptionId, but has totalPremiumAmount on plan level
      if (plan?.totalPremiumAmount > 0) {
        if (plan.require9DigitSocial) _require9DigitSocial = true;
      } else if (
        plan.selectedOptionId != 0 &&
        plan?.options &&
        plan.options.length > 0
      ) {
        for (let option of plan.options) {
          //check that the selected option isnt a decline
          if (
            option.isDecline &&
            option.memberOptionId != plan.selectedOptionId
          ) {
            if (plan.require9DigitSocial) _require9DigitSocial = true;
          }
        }
      }
    }
    setRequire9DigitSocial(_require9DigitSocial);
    // console.log("this person requires a full social:", _require9DigitSocial)
  };

  const getFullSocial = async (id) => {
    try {
      let _fullSocial = await memberService.getMemberSocialByIdAsync(id);
      if (_fullSocial) setFullSocial(_fullSocial);
      // console.log("fullSoc", _fullSocial);
    } catch (error) {
      console.log('error grabbing full social');
    }
  };

  const setSocial = () => {
    if (require9DigitSocial) {
      setFullMemberObj({
        ...fullMemberObj,
        social: fullSocial?.social,
      });
    } else {
      setFullMemberObj({
        ...fullMemberObj,
        social: fullSocial?.social.slice(-4),
      });
    }
  };

  useEffect(() => {
    if (results) {
      checkRequireSocial();
    }
  }, [results]);

  useEffect(() => {
    if (fullSocial?.social) {
      setSocial();
    }
  }, [fullSocial?.social, require9DigitSocial]);

  // useEffect(() => {
  //   if (results?.require9DigitSocial && !fullSocial && fullMemberObj)
  //     getFullSocial();
  // }, [results, fullMemberObj]);

  //Recc state,effects
  const [recommendations, setRecomendations] = useState({});
  const getStoredRecommendations = async () => {
    let _survey = await getStoredSurvey();
    if (_survey) {
      try {
        let _surveyResults;
        _surveyResults = await calculatorService.getRecommendationsForMember(
          fullMemberObj.id,
          _survey
        );
        setRecomendations(_surveyResults);
      } catch (error) {
        console.log('error getting recommendations', error);
      }
    }
  };
  const getStoredSurvey = async (e) => {
    let storedSurvey;

    try {
      storedSurvey =
        await calculatorService.getRecommendationResponsesForMember(
          fullMemberObj.id
        );
    } catch (error) {
      console.log('error getting stored survey', error);
    }

    if (storedSurvey) {
      if (storedSurvey?.policyCount == null) storedSurvey.policyCount = 0;

      return storedSurvey;
    }
  };
  useEffect(() => {
    if (fullMemberObj?.id > 0) {
      getStoredRecommendations();
    }
  }, [fullMemberObj?.id]);

  //Selections state,handlers,effects
  const [runningTotal, setRunningTotal] = useState(0);
  const [selections, setSelections] = useState({
    std: undefined, //optionId
    stdVal: 0, //PremiumAmount
    ltd: undefined, //optionId
    ltdVal: 0, //PremiumAmount
    ladd: [0, 0, 0], // Coverage Amounts
    laddVal: [0, 0, 0], //Premium amounts
    laddId: undefined, //planId
  });
  useEffect(() => {
    props.setRunningTotal(runningTotal);
  }, [runningTotal]);

  //FLIPPING THE CHIP VIA REFRESH WITH THIS
  const [enrollmentCreated, setEnrollmentCreated] = useState(false);
  useEffect(() => {
    if (props.singleEnrollment.enrollmentId == 0) {
      setEnrollmentCreated(false);
    } else {
      setEnrollmentCreated(null);
    }
  }, [props.singleEnrollment]);
  // console.log(props)

  const refreshEnrollments = async () => {
    await props.getEnrollments();
  };

  useEffect(() => {
    if (enrollmentCreated === null) {
      //we already have an enrollment with non 0 enrollmentId
      //look for this prev val when setting to avoid unnecessary calls
    } else if (enrollmentCreated === false) {
      //we have an enrollment that isnt created
    } else if (enrollmentCreated === true) {
      //a selection has been made, and the enrollment is now in progress
      // console.log("refreshing enrollment");
      refreshEnrollments();
    }
  }, [enrollmentCreated]);

  const selectionHandler = (e) => {
    setTimeLeft('About 2 minutes left');
    setProgress(40);
    if (e.target != null) {
      let premium = parseFloat(e.target.getAttribute('data-amount'));
      let value = parseFloat(e.target.value);
      let plan = e.target.name;
      let planVal = plan + 'Val';
      setSelections({
        ...selections,
        [plan]: value,
        [planVal]: premium,
      });
    } else {
      let premium = parseFloat(e.getAttribute('data-amount'));
      let value = parseFloat(e.value);
      let plan = e.name;
      let planVal = plan + 'Val';

      // console.log("SelectionHandler", premium, value, plan, planVal)

      setSelections({
        ...selections,
        [plan]: value,
        [planVal]: premium,
      });
    }
  };

  const selectionRec = (options, index) => {
    let value = options[index];
    let newArray = options.map((x) => x);

    //assuming last one is the 'not now' option, pop it off the array.
    newArray.pop();

    newArray.sort((a, b) => {
      return a.memberPremiumAmount - b.memberPremiumAmount;
    });
    let sortedIndex = newArray.findIndex(
      (x) => x.memberOptionId === value.memberOptionId
    );

    if (recommendations.Lowest && sortedIndex == 0) return true;
    if (
      newArray.length > 2 &&
      !recommendations.Lowest &&
      sortedIndex == newArray.length - 2
    )
      return true;
    if (
      newArray.length <= 2 &&
      !recommendations.Lowest &&
      sortedIndex == newArray.length - 1
    )
      return true;
    else return false;
  };

  useEffect(() => {
    let selectionsAsArray = Object.entries(selections);
    let _total = 0;
    for (let [key, value] of selectionsAsArray) {
      //Adding up our monthly cost
      if (typeof value == 'object' && key.includes('Val')) {
        //It's a num array(ladd), so get the total
        let sum = value.reduce(
          (prevValue, currValue) => prevValue + currValue,
          0
        );
        _total += sum;
      } else if (key.includes('Val')) _total += parseFloat(value);
    }
    // if (_total > 0) _total += results?.transactionFee;
    setRunningTotal(_total);
  }, [selections]);

  const [calculated, setCalculated] = useState(false);
  // const [calculated,setCalculated] = useState(true) //debug

  //this is whats being passed with thisUserContext
  let contextProps = {
    userInfo: userInfo,
    setUserInfo: () => setUserInfo(),
  };

  // Saving Progress
  let saveLadd = async () => {
    // console.log('saving ladd')
    let groupPlanId = results.groupPlanId;
    let planId = selections.laddId;
    let memeberCoverage = selections.ladd[0];
    let spouseCoverage = selections.ladd[1];
    let dependentCoverage = selections.ladd[2];

    // console.log('saving LADD in Agent planId', planId)

    try {
      await enrollmentService.saveLADDForMember(
        fullMemberObj.id,
        groupPlanId,
        planId,
        memeberCoverage,
        spouseCoverage,
        dependentCoverage
      );
      setEnrollmentCreated((prevState) => {
        if (prevState === null || prevState === true) {
          return prevState;
        } else {
          return true;
        }
      });
    } catch (error) {
      console.log('error saving ladd', error);
    }
  };

  //get gender types
  const [genderTypes, setGenderTypes] = useState([]);

  const getGenderTypes = async () => {
    let _genderTypes = [];
    try {
      _genderTypes = await optionService.getGenderTypes();
    } catch (error) {
      console.log('error getting gender type list', error);
    }
    if (_genderTypes) setGenderTypes(_genderTypes);
  };

  useEffect(() => {
    if (genderTypes.length == 0) getGenderTypes();
  }, []);

  //SPOUSE
  const [spouseInDb, setSpouseInDb] = useState({});
  const [spouse, setSpouse] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    email: '',
    gender: 0,
    informationAuthorize: false,
    coverageAuthorize: null,
    spouseInDb: false, //Dont send this property back
  });

  const [spouseUpdateTimer, setSpouseUpdateTimer] = useState(null);
  const [spouseRatesLoading, setSpouseRatesLoading] = useState(false);
  //if a plan has spouse rates, we need to be able to refresh the results to get update rates
  useEffect(() => {
    clearTimeout(spouseUpdateTimer);
    if (spouseRatesRequired) {
      let timerId = setTimeout(() => {
        updateResults();
        setSpouseRatesLoading(false);
      }, 800);
      setSpouseRatesLoading(true);
      setSpouseUpdateTimer(timerId);
    }
  }, [spouse.dateOfBirth]);

  const getSpouse = async (e) => {
    let _spouse;
    try {
      _spouse = await memberService.getSpouseForMember(fullMemberObj.id);
      if (_spouse) {
        _spouse.spouseInDb = true;
      } else {
        _spouse = {
          firstName: '',
          lastName: '',
          dateOfBirth: null,
          email: '',
          informationAuthorize: false,
          coverageAuthorize: null,
          spouseInDb: false,
        };
      }
    } catch (error) {
      console.log('error getting spouse', error);
      _spouse = {
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        email: '',
        gender: 0,
        informationAuthorize: false,
        coverageAuthorize: null,
        spouseInDb: false,
      };
      setSpouse(_spouse);
    }

    //format dob from db debending on device
    let formattedDob = null;
    if (_spouse?.dateOfBirth) {
      //format dob w/ timezone
      const dobWithTimezone = formatUTCDateToObjWithTimeZone(
        _spouse?.dateOfBirth
      );
      //format further for device
      // if(isMobileDevice){
      //   formattedDob = dobWithTimezone //MUI datepicker format
      // }else{
      //   formattedDob = format(new Date(dobWithTimezone), "yyyy-MM-dd"); //MUI textfield format
      // }
      formattedDob = dobWithTimezone;
    }
    setSpouse({ ..._spouse, dateOfBirth: formattedDob });
    setSpouseInDb({ ..._spouse });
  };

  let saveSpouseInfo = async () => {
    //update existing spouse (existing in db if they have an id and spouseInDb is true)
    if (
      spouse.spouseInDb &&
      JSON.stringify(spouse) != JSON.stringify(spouseInDb) &&
      spouse.id
    ) {
      let updateSpouseObj = { ...spouse };
      delete updateSpouseObj.spouseInDb;
      try {
        await memberService.updateSpouseForMember(
          fullMemberObj.id,
          updateSpouseObj
        );
        // console.log('updating spouse for member', spouse)
      } catch (error) {
        console.error('error updating spouse', error);
      }
    } else if (!spouse.spouseInDb && spouse.firstName !== '' && !spouse.id) {
      let addSpouseObj = { ...spouse };
      delete addSpouseObj.spouseInDb;

      try {
        await memberService.addSpouseForMember(fullMemberObj.id, addSpouseObj);
        // console.log('adding spouse for member')
      } catch (error) {
        console.error('error adding spouse', error);
      }
    }

    //check if spouse exists as a beneficiary AND if spouse values have changed - if yes, update spouse info in the beneficiary table
    let spouseIsBeneficiaryInDb = beneficiariesInDB?.find((benny) => {
      return benny.relationshipTypeId === 1;
    });
    if (
      spouseIsBeneficiaryInDb &&
      JSON.stringify(spouse) != JSON.stringify(spouseInDb)
    ) {
      let _updatedSpouseBennyObj = {
        firstName: spouse.firstName,
        middleInitial: spouseIsBeneficiaryInDb?.middleInitial || '',
        lastName: spouse.lastName,
        dob: spouse.dateOfBirth,
        memberId: fullMemberObj.id,
        id: spouseIsBeneficiaryInDb?.id,
        relationshipTypeId: 1,
        isDeleted: spouseIsBeneficiaryInDb?.isDeleted || false,
        lastModifiedBy: spouseIsBeneficiaryInDb?.lastModifiedBy,
        amount: spouseIsBeneficiaryInDb?.amount,
      };
      try {
        // console.log("updating spouse benny", _updatedSpouseBennyObj)
        await enrollmentService.updateBeneficiaryForMember(
          fullMemberObj.id,
          _updatedSpouseBennyObj
        );
      } catch (error) {
        console.error('error updating spouse benny', error);
      }
    }

    //refresh spouse and beneficiaries
    await getSpouse();
  };

  //DEPENDENTS
  const [dependentsInDB, setDependentsInDB] = useState([]);
  const [dependents, setDependents] = useState([]);
  const getDependents = async () => {
    let _dependents;
    try {
      _dependents = await memberService.getDependentsForMember(
        fullMemberObj.id
      );
      // console.log("dependents in agent", _dependents);
      // console.log("dependentsINDB in agent", dependentsInDB);
    } catch (error) {
      console.log('error getting deps', error);
    }
    let _dependentsInDB = [];

    _dependents.forEach((_dependent) => {
      //format dob from db debending on device
      let formattedDob = null;
      if (_dependent?.dob) {
        // _dependent.dob = formatUTCDateToObjWithTimeZone(_dependent?.dob)
        const dobWithTimezone = formatUTCDateToObjWithTimeZone(_dependent?.dob);
        //format further for device
        // if(isMobileDevice){
        //   formattedDob = dobWithTimezone //MUI datepicker format
        // }else{
        //   formattedDob = format(new Date(dobWithTimezone), "yyyy-MM-dd"); //MUI textfield format
        // }
        formattedDob = dobWithTimezone;
      }
      _dependent.dob = formattedDob;
      _dependent.dobError = null;
      _dependentsInDB.push({ ..._dependent, dob: formattedDob });
    });

    setDependentsInDB(_dependentsInDB);
    setDependents(_dependents);
  };

  const [spouseAndDepsLoaded, setSpouseAndDepsLoaded] = useState(false);
  useEffect(() => {
    if (!spouseAndDepsLoaded && fullMemberObj.id) {
      getDependents();
      getSpouse();
      setSpouseAndDepsLoaded(true);
    }
  }, [fullMemberObj]);

  //Grab spouse changes
  useEffect(() => {
    // console.log('spouse or deps changing in props')
    if (spouseAndDepsLoaded) {
      getSpouse();
      getDependents();
      if (agentEnrollmentPageState?.beneficiaries) {
        getInitialBeneficiaries();
      }
    }
    // getInitialBeneficiaries()
  }, [props.member]);

  let saveDependents = async () => {
    // console.log('saving deps', dependents)
    for (let editDependent of dependents) {
      let dependentInDB = dependentsInDB.find(
        (DBdependent) => DBdependent.id == editDependent.id
      );
      // console.log('edit', editDependent)
      // console.log('db', dependentInDB)
      //check for delete dep
      if (dependentInDB && editDependent.isDeleted != dependentInDB.isDeleted) {
        // console.log('deleted on the front')
        try {
          await memberService.deleteDependentForMember(
            fullMemberObj.id,
            editDependent.id
          );
        } catch (error) {
          console.log('error deleting dep', error);
        }
      }
      //check for updated dep
      else if (
        dependentInDB &&
        JSON.stringify(editDependent) != JSON.stringify(dependentInDB)
      ) {
        delete editDependent?.uid;
        delete editDependent?.dobError;
        try {
          await memberService.updateDependentForMember(
            fullMemberObj.id,
            editDependent
          );
        } catch (error) {
          console.log('error uopdating dep', error);
        }
      }

      //check for new dep
      else if (!dependentInDB && !editDependent.isDeleted) {
        // console.log('added dep on front')
        delete editDependent?.uid;
        delete editDependent?.dobError;
        try {
          await memberService.addDependentForMember(
            fullMemberObj.id,
            editDependent
          );
        } catch (error) {
          console.log('error adding dep', error);
        }
      }
    }
    getDependents();
  };
  //BENNY LOGIC
  const [benficiariesObj, setBeneficiariesObj] = useState({});
  //if it's checked in primary, remove it from contingent
  const [primaryCheckboxes, setPrimaryCheckboxes] = useState([]);
  const [contingentCheckboxes, setContingentCheckboxes] = useState([]);

  //State arrays to display, handle adding to 'SentBack' via checkbox
  const [primaryBeneficiaries, setPrimaryBeneficiaries] = useState([]);
  // console.log(primaryBeneficiaries)
  const [contingentBeneficiaries, setContingentBeneficiaries] = useState([]);

  //Initial list of potential benny's builts from spouse/dep, combined with benny's in db already
  const [beneficiariesInDB, setBeneficiariesInDB] = useState([]);

  const [potentialBeneficiaries, setPotentialBeneficiaries] = useState();
  const [storedAllocations, setStoredAllocations] = useState([]);

  const getInitialBeneficiaries = async () => {
    //Check for isBeneficiary
    let plans = [];
    if (results) {
      for (let [key, value] of Object.entries(results)) {
        if (key.slice(key.length - 4, key.length) === 'Plan' && value != null) {
          plans.push(value);
        }
      }
    }
    let electedBennyRequiredPlanIds = [];
    for (let plan of plans) {
      //check for user selection (decline or otherwise)
      //ladd doesnt have selectedOptionId, but has totalPremiumAmount on plan level
      if (plan?.totalPremiumAmount > 0) {
        if (plan.isBeneficiaryEnabled)
          electedBennyRequiredPlanIds.push(plan.planId);
      } else if (
        plan.selectedOptionId != 0 &&
        plan?.options &&
        plan.options.length > 0
      ) {
        for (let option of plan.options) {
          //check that the selected option isnt a decline
          if (
            option.isDecline &&
            plan.selectedOptionId != option.memberOptionId
          ) {
            if (plan.isBeneficiaryEnabled)
              electedBennyRequiredPlanIds.push(plan.planId);
          }
        }
      }
    }

    let laddIdOverRide = null;
    if (results?.laddPlan?.totalPremiumAmount > 0)
      laddIdOverRide = results?.laddPlan?.planId;

    let planIdForCall = laddIdOverRide
      ? laddIdOverRide
      : electedBennyRequiredPlanIds[0];

    //GET BENEFICIARIES
    let _beneficiariesInDB = [];
    try {
      _beneficiariesInDB = await enrollmentService.getBeneficiariesForMember(
        fullMemberObj?.id,
        planIdForCall
      ); //FOR MEMBER
    } catch (error) {
      console.log('error getting bennies', error);
    }

    //check for 'deleted beneficiaries' and do not display
    let filteredBeneficiariesInDB = _beneficiariesInDB.filter(
      (benny) => !benny.isDeleted
    );
    setBeneficiariesInDB(filteredBeneficiariesInDB);

    //GET POTENTIAL BENEFICIARIES - these can be duplicates of beneficiaries from above, can also include dependents and spouse if they are not already selected as beneficiaries
    let _potentials;
    try {
      _potentials = await enrollmentService.getPotentialBeneficiariesForMember(
        fullMemberObj?.id
      ); //FOR MEMBER
      console.log('potentials from agent', _potentials);
    } catch (error) {
      console.log('error getting potential bennies', error);
    }

    setPotentialBeneficiaries(_potentials);

    //Filter out existing bennies in db from potentials
    let initialBuiltArray = [];
    for (let beneficiary of filteredBeneficiariesInDB) {
      initialBuiltArray.push({ ...beneficiary });
    }
    for (let potentialBeneficiary of _potentials) {
      let potentialFirstName = potentialBeneficiary.firstName;
      let potentialLastName = potentialBeneficiary.lastName;

      //determine if in potential list based on first and last names. loop through built array which has db bennies, if the potential doesnt match, push it to the built array so it can be an additional option to select
      let potentialNotInDb = true;
      for (let dbBeneficiary of initialBuiltArray) {
        let dbFirstName = dbBeneficiary.firstName;
        let dbLastName = dbBeneficiary.lastName;
        // console.log('names\n\n\n',potentialFirstName,dbFirstName,potentialLastName,dbLastName)
        if (
          potentialFirstName == dbFirstName &&
          potentialLastName == dbLastName
        ) {
          potentialNotInDb = false;
        }
      }
      if (potentialNotInDb) {
        initialBuiltArray.push({ ...potentialBeneficiary });
      }
    }

    // console.log('initial built array/n/n/n',initialBuiltArray)
    setPrimaryBeneficiaries(initialBuiltArray); //includes all bennies initially before any are selected

    //Initial Contingents
    let initialContingents = [];
    initialBuiltArray.forEach((benny) => {
      // if (benny.relationshipTypeId != 1) initialContingents.push({ ...benny });
      initialContingents.push({ ...benny });
    });
    setContingentBeneficiaries(initialContingents);

    //get allocations from db
    let allocationsExist = false;
    if (planIdForCall > 0 && fullMemberObj?.id > 0) {
      try {
        allocationsExist =
          await enrollmentService.getBeneficiaryAllocationsForMember(
            planIdForCall,
            fullMemberObj?.id
          );
      } catch (error) {
        console.log('error checking for allocations', error);
      }
    }
    //set stored allocations
    if (allocationsExist) setStoredAllocations(allocationsExist);

    //CREATING CHECKED BENEFICIARY arrays
    let primaryInitialChecks = [];
    let contingentInitialChecks = [];
    console.log('allocations exist', allocationsExist);
    if (allocationsExist?.length > 0) {
      for (let bennyObj of allocationsExist) {
        let allocationObj;
        allocationObj = {
          id: bennyObj.id,
          PlanId: bennyObj.planId,
          // BeneficiaryId: "",
          amount: bennyObj.amount,
          isPrimary: bennyObj.isPrimary,
          // IsDeleted: false,
          checked: true,
        };
        //push to primary
        if (allocationObj.isPrimary) {
          primaryInitialChecks.push(allocationObj);
          //push to contingent
        } else {
          // console.log('pushing to contingent',allocationObj)
          contingentInitialChecks.push(allocationObj);
        }
      }

      // console.log('mega initial contingent checks',contingentInitialChecks)
      //comparing allocations we have in db to the initial built array

      //PRIMARIES ////////////////////////////////////
      if (initialBuiltArray?.length > 0) {
        //check in initial built array
        for (let bennyObj of initialBuiltArray) {
          let allocationObj;
          //if benny exists in db, will have id
          if (bennyObj.id) {
            //if existing primary bennies, skip over creating allocatioObj as this data exists
            if (primaryInitialChecks.find((check) => check.id == bennyObj.id))
              continue;
            allocationObj = {
              id: bennyObj.id,
              PlanId: planIdForCall,
              // BeneficiaryId: "",
              amount: 0,
              isPrimary: true,
              // IsDeleted: false,
              checked: false,
            };
            //default spouse allocation IF spouse benny and allocation does not already exist in db
            if (bennyObj.relationshipTypeId === 1) {
              allocationObj.amount = 100;
              allocationObj.checked = true;
            }
          } else if (bennyObj.dependentId) {
            //if potential benny, not in db as actual beneficiary & has a dependentId
            if (
              primaryInitialChecks.find(
                (check) => check.dependentId == bennyObj.dependentId
              )
            )
              continue;
            allocationObj = {
              dependentId: bennyObj.dependentId,
              PlanId: planIdForCall,
              // BeneficiaryId: "",
              amount: 0,
              isPrimary: true,
              // IsDeleted: false,
              checked: false,
            };
            if (bennyObj.relationshipTypeId === 1) {
              allocationObj.amount = 100;
              allocationObj.checked = true;
            }
          }
          primaryInitialChecks.push(allocationObj);
        }
      }
      //CONTINGENTS///////////////
      if (initialContingents?.length > 0) {
        for (let bennyObj of initialContingents) {
          let allocationObj;
          if (bennyObj.id) {
            //if existing contingent bennies, skip over creating allocatioObj as this data exists
            if (
              contingentInitialChecks.find((check) => check.id == bennyObj.id)
            )
              continue;
            allocationObj = {
              id: bennyObj.id,
              PlanId: planIdForCall,
              // BeneficiaryId: "",
              amount: 0,
              isPrimary: false,
              // IsDeleted: false,
              checked: false,
            };
          } else if (bennyObj.dependentId) {
            //if potential benny, not in db as actual beneficiary & has a dependentId
            if (
              contingentInitialChecks.find(
                (check) => check.dependentId == bennyObj.dependentId
              )
            )
              continue;
            allocationObj = {
              dependentId: bennyObj.dependentId,
              PlanId: planIdForCall,
              // BeneficiaryId: "",
              amount: 0,
              isPrimary: false,
              // IsDeleted: false,
              checked: false,
            };
            //if spouse and no amount exists yet and there are NO other beneficiaries in the db, set to 100%
            if (
              bennyObj.relationshipTypeId == 1 &&
              (!bennyObj.amount || bennyObj.amount == 0) &&
              !bennyObj.isDeleted
            ) {
              allocationObj.amount = 100;
              allocationObj.checked = true;
            } else if (
              bennyObj.relationshipTypeId === 1 &&
              bennyObj.amount > 0
            ) {
              allocationObj.amount = bennyObj.amount;
              allocationObj.checked = true;
            }
          }
          contingentInitialChecks.push(allocationObj);
        }
      }

      // console.log('0 initial contingent checks',contingentInitialChecks)
    } else {
      console.log('***************no allocations exist');
      primaryInitialChecks = [];

      for (let bennyObj of initialBuiltArray) {
        let allocationObj;
        if (bennyObj.id) {
          allocationObj = {
            id: bennyObj.id,
            // PlanId: props.results?.lifeADDPlan?.planId,
            // BeneficiaryId: "",
            amount: 0,
            // isPrimary: true,
            // IsDeleted: false,
            checked: false,
          };
          //if spouse and no amount exists yet and there are NO other beneficiaries in the db, set to 100%
          if (
            bennyObj.relationshipTypeId == 1 &&
            (!bennyObj.amount || bennyObj.amount == 0) &&
            !bennyObj.isDeleted
          ) {
            allocationObj.amount = 100;
            allocationObj.checked = true;
          } else if (bennyObj.relationshipTypeId === 1 && bennyObj.amount > 0) {
            allocationObj.amount = bennyObj.amount;
            allocationObj.checked = true;
          }
        } else if (bennyObj.dependentId) {
          allocationObj = {
            dependentId: bennyObj.dependentId,
            // PlanId: props.results?.lifeADDPlan?.planId,
            // BeneficiaryId: "",
            amount: 0,
            // isPrimary: true,
            // IsDeleted: false,
            checked: false,
          };
          //if spouse and no amount exists yet and there are NO other beneficiaries in the db, set to 100%
          if (
            bennyObj.relationshipTypeId == 1 &&
            (!bennyObj.amount || bennyObj.amount == 0) &&
            !bennyObj.isDeleted
          ) {
            allocationObj.amount = 100;
            allocationObj.checked = true;
          } else if (bennyObj.relationshipTypeId === 1 && bennyObj.amount > 0) {
            allocationObj.amount = bennyObj.amount;
            allocationObj.checked = true;
          }
        }
        primaryInitialChecks.push(allocationObj);
      }
      //filter out contingentInitialChecks
      contingentInitialChecks = primaryInitialChecks.filter((benny) => {
        return !benny.checked;
      });
    }
    setContingentCheckboxes(contingentInitialChecks);
    setPrimaryCheckboxes(primaryInitialChecks);
    // console.log('FINAL initial contingent checks',contingentInitialChecks)
  };

  ///get all allocations from ALL potential plan ids that require beneficiaries, create array of all planIds with existing allocations to use to monitor if there are changes in mulitple plan ids selected
  const [plansWithSavedAllocations, setPlansWithSavedAllocations] = useState(
    []
  );
  const getAllPlanIdsWithAllocations = async () => {
    let _plansWithSavedAllocations = [];
    let electedBennyRequiredPlanIds = buildBennyPlanIdArray();
    if (electedBennyRequiredPlanIds?.length > 0) {
      for (let planId of electedBennyRequiredPlanIds) {
        let allocationsExist = false;
        try {
          allocationsExist = await enrollmentService.getBeneficiaryAllocations(
            planId
          );
        } catch (error) {
          console.log('error checking for allocations', error);
        }
        //if allocations in db associated with plan id, push to array
        if (allocationsExist?.length > 0)
          _plansWithSavedAllocations.push(planId);
      }
      console.log('plansWithSavedAllocations', _plansWithSavedAllocations);
      setPlansWithSavedAllocations(_plansWithSavedAllocations);
    }
  };

  //refresh planIds with allocations if bennies in db change
  useEffect(() => {
    if (results && fullMemberObj?.id) {
      getAllPlanIdsWithAllocations();
    }
  }, [beneficiariesInDB?.length]);

  const [primaryBennyAdd, setPrimaryBennyAdd] = useState(false);
  const [primaryBennyEdit, setPrimaryBennyEdit] = useState(false);

  const [contingentBennyAdd, setContingentBennyAdd] = useState(false);
  const [contingentBennyEdit, setContingentBennyEdit] = useState(false);

  const buildSaveBackArray = async () => {
    let _builtArray = [];

    //build from primary
    let index = 0;
    if (primaryCheckboxes?.length > 0) {
      for (let primary of primaryCheckboxes) {
        if (primary.checked) {
          if (index == 0 && primary.amount == 33.33) {
            primary.amount = 33.34;
          }
          let infoId;
          let potential = false;

          if (primary.dependentId) {
            infoId = primary.dependentId;
            potential = true;
          } else if (primary.id) infoId = primary.id;

          if (!potential) {
            let builtBenny = {
              PlanId: results.lifeADDPlan?.planId || 0,
              BeneficiaryId: infoId,
              Amount: primary.amount,
              isPrimary: true,
              IsDeleted: false,
            };
            _builtArray.push(builtBenny);
          } else {
            let potentialBenny = primaryBeneficiaries.find((beneficiary) => {
              return beneficiary.dependentId == infoId;
            });

            let insertBenny = {
              FirstName: potentialBenny.firstName,
              MiddleInitial: potentialBenny.MiddleInitial || '',
              LastName: potentialBenny.lastName,
              RelationShipTypeId: potentialBenny.relationshipTypeId,
              // dob: potentialBenny.dob || null,
              IsDeleted: false,
            };
            if (potentialBenny.dob) {
              insertBenny.dob = potentialBenny.dob;
            }
            // console.log(insertBenny)
            let newBenny;
            try {
              newBenny = await enrollmentService.addBeneficiaryForMember(
                fullMemberObj.id,
                insertBenny
              ); //FOR MEMBER
            } catch (error) {
              console.log('error adding benny', error);
            }

            let builtBenny = {
              PlanId: results.lifeADDPlan?.planId || 0,
              BeneficiaryId: newBenny?.id,
              Amount: primary.amount,
              isPrimary: true,
              IsDeleted: false,
            };
            _builtArray.push(builtBenny);
          }
          index++;
        }
      }
    }

    //build from contingent
    let contingentIndex = 0;
    if (contingentCheckboxes?.length > 0) {
      for (let contingent of contingentCheckboxes) {
        if (contingent.checked) {
          if (contingentIndex == 0 && contingent.amount == 33.33) {
            contingent.amount = 33.34;
          }
          let infoId;
          let potential = false;

          if (contingent.dependentId) {
            infoId = contingent.dependentId;
            potential = true;
          } else if (contingent.id) infoId = contingent.id;

          if (!potential) {
            let builtBenny = {
              PlanId: results.lifeADDPlan?.planId || 0,
              BeneficiaryId: infoId,
              Amount: contingent.amount,
              isPrimary: false,
              IsDeleted: false,
            };
            _builtArray.push(builtBenny);
          } else {
            let potentialBenny = contingentBeneficiaries.find((beneficiary) => {
              return beneficiary.dependentId == infoId;
            });

            let insertBenny = {
              FirstName: potentialBenny.firstName,
              MiddleInitial: potentialBenny.MiddleInitial || '',
              LastName: potentialBenny.lastName,
              RelationShipTypeId: potentialBenny.relationshipTypeId,
              // dob: potentialBenny.dob || null,
              IsDeleted: false,
            };
            if (potentialBenny.dob) {
              insertBenny.dob = potentialBenny.dob;
            }
            // console.log(insertBenny)
            let newBenny;
            try {
              newBenny = await enrollmentService.addBeneficiaryForMember(
                fullMemberObj.id,
                insertBenny
              ); //FOR MEMBER
            } catch (error) {
              console.log('error adding contingent benny');
            }

            let builtBenny = {
              PlanId: results.lifeADDPlan?.planId || 0,
              BeneficiaryId: newBenny?.id,
              Amount: contingent.amount,
              isPrimary: false,
              IsDeleted: false,
            };
            _builtArray.push(builtBenny);
          }
          contingentIndex++;
        }
      }
    }
    // console.log(_builtArray)
    return _builtArray;
    // await enrollmentService.saveBeneficiaryAllocation(_builtArray)
  };

  const [benniesLoaded, setBenniesLoaded] = useState(false);
  useEffect(() => {
    if (!benniesLoaded && fullMemberObj?.id > 0 && results) {
      getInitialBeneficiaries();
      setBenniesLoaded(true);
    }
  }, [fullMemberObj, results, spouse]);

  const handleLaddSaveAndContinue = async () => {
    await saveLadd();
    await saveSpouseInfo();
    await saveDependents();
    await updateResults();
    await getInitialBeneficiaries();
  };

  const buildBennyPlanIdArray = () => {
    //Check for isBeneficiary
    let plans = [];
    for (let [key, value] of Object.entries(results)) {
      if (key.slice(key.length - 4, key.length) === 'Plan' && value != null) {
        plans.push(value);
      }
    }
    let electedBennyRequiredPlanIds = [];
    for (let plan of plans) {
      //check for user selection (decline or otherwise)
      //ladd doesnt have selectedOptionId, but has totalPremiumAmount on plan level
      if (plan?.totalPremiumAmount > 0) {
        if (plan.isBeneficiaryEnabled)
          electedBennyRequiredPlanIds.push(plan.planId);
      } else if (
        plan.selectedOptionId != 0 &&
        plan?.options &&
        plan.options.length > 0
      ) {
        for (let option of plan.options) {
          //check that the selected option isnt a decline
          if (
            option.isDecline &&
            plan.selectedOptionId != option.memberOptionId
          ) {
            if (plan.isBeneficiaryEnabled)
              electedBennyRequiredPlanIds.push(plan.planId);
          }
        }
      }
    }
    return electedBennyRequiredPlanIds;
  };

  const [localBennyRequiredPlans, setLocalBennyRequiredPlans] = useState({});
  const [localSpouseRatePlans, setLocalSpouseRatePlans] = useState({});
  useEffect(() => {
    if (selections) {
      let builtLocalBennyRequiredPlans = {
        std: false,
        ltd: false,
        ladd: false,
        accident: false,
        cancer: false,
        add: false,
        hospital: false,
      };

      let builtLocalSpouseRatePlans = {
        std: false,
        ltd: false,
        ladd: false,
        accident: false,
        cancer: false,
        add: false,
        hospital: false,
      };

      //std
      if (results?.stdPlan && selections.stdVal > 0) {
        builtLocalBennyRequiredPlans.std = results.stdPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.std = results.stdPlan.spouseRatesBasedOn == 1;
      } else {
        builtLocalBennyRequiredPlans.std = false;
        builtLocalSpouseRatePlans.std = false;
      }

      //ltd
      if (results?.ltdPlan && selections.ltdVal > 0) {
        builtLocalBennyRequiredPlans.ltd = results.ltdPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.ltd = results.ltdPlan.spouseRatesBasedOn == 1;
      } else {
        builtLocalBennyRequiredPlans.ltd = false;
        builtLocalSpouseRatePlans.ltd = false;
      }

      //ladd
      if (results?.lifeADDPlan && selections.laddVal[0] > 0) {
        builtLocalBennyRequiredPlans.ladd =
          results.lifeADDPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.ladd =
          results.lifeADDPlan.spouseRatesBasedOn == 1;
      } else {
        builtLocalBennyRequiredPlans.ladd = false;
        builtLocalSpouseRatePlans.ladd = false;
      }

      //accident
      if (results?.accidentPlan && selections.accidentVal > 0) {
        builtLocalBennyRequiredPlans.accident =
          results.accidentPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.accident =
          results.accidentPlan.spouseRatesBasedOn == 1;
      } else {
        builtLocalBennyRequiredPlans.accident = false;
        builtLocalSpouseRatePlans.accident = false;
      }

      //cancer
      if (results?.cancerPlan && selections.cancerVal > 0) {
        builtLocalBennyRequiredPlans.cancer =
          results.cancerPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.cancer =
          results.cancerPlan.spouseRatesBasedOn == 1;
      } else {
        builtLocalBennyRequiredPlans.cancer = false;
        builtLocalSpouseRatePlans.cancer = false;
      }

      //add
      if (results?.addPlan && selections.addVal > 0) {
        builtLocalBennyRequiredPlans.add = results.addPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.add = results.addPlan.spouseRatesBasedOn == 1;
      } else {
        builtLocalBennyRequiredPlans.add = false;
        builtLocalSpouseRatePlans.add = false;
      }

      //hospital
      if (results?.hospitalPlan && selections.hospitalVal > 0) {
        builtLocalBennyRequiredPlans.hospital =
          results.hospitalPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.hospital =
          results.hospitalPlan.spouseRatesBasedOn == 1;
      } else {
        builtLocalBennyRequiredPlans.hospital = false;
        builtLocalSpouseRatePlans.hospital = false;
      }
      setLocalBennyRequiredPlans(builtLocalBennyRequiredPlans);
      setLocalSpouseRatePlans(builtLocalSpouseRatePlans);
    }
  }, [selections]);

  //Delete a beneficiary - needs work - need to reallocate deleted benny amount to remaining bennies on delete
  //will this require separate updates to each remaining benny where new amounts are calculated? Or happen to remaining bennies on backend?

  const deleteBeneficiary = async (memberId, beneficiaryId, dependentId) => {
    // console.log("memberId", memberId)
    // console.log("beneficiaryId", beneficiaryId)
    // console.log("dependentId", dependentId)
    try {
      if (beneficiaryId) {
        await enrollmentService.deleteBeneficiaryByMemberId(
          memberId,
          beneficiaryId
        );
        // console.log("deleting beneficiary with bennyId...")
        //refresh beneficiaries
        getInitialBeneficiaries();
      }
      // else if(dependentId){
      //   await enrollmentService.deleteBeneficiaryByDependentId(memberId, dependentId)
      // }
    } catch (error) {
      console.error('Error deleting beneficiary', error);
    }
  };

  const checkIfBennyRequired = () => {
    let required = false;
    for (let [key, value] of Object.entries(localBennyRequiredPlans)) {
      if (value) required = true;
    }

    setBenRequired(required);
    return required;
  };

  const checkIfSpouseBasedRates = () => {
    let spouseBasedRates = false;
    for (let [key, value] of Object.entries(localSpouseRatePlans)) {
      if (value) spouseBasedRates = true;
    }
    setSpouseRatesRequired(spouseBasedRates);

    return spouseBasedRates;
  };
  useEffect(() => {
    checkIfSpouseBasedRates();
  }, [localSpouseRatePlans]);

  let handleNextClick = async (path) => {
    setLoading(true);
    if (path == 'beneficiaries') {
      await saveLadd();
      await saveSpouseInfo();
      await saveDependents();
      await updateResults();
      await getInitialBeneficiaries();

      setLoading(false);
      //if member isn't selected, ladd is declined
      if (checkIfBennyRequired() == false) {
        setTimeLeft('About 1 minute left');
        setProgress(80);
        setAgentEnrollmentPageState({ personalInfo: true });
      } else {
        setTimeLeft('Less than 2 minutes left');
        setProgress(60);
        setAgentEnrollmentPageState({ [path]: true });
      }
    }
    if (path == 'payment') {
      // console.log('updating member info in next click', fullMemberObj)
      //updating member
      try {
        await memberService.updateForMember(fullMemberObj.id, fullMemberObj);
      } catch (error) {
        console.log('error updating member', error);
      }
      //update member organization after next click from personal info page
      let _updatedMemberOrg = {
        memberId: fullMemberObj?.id,
        organizationId: fullMemberObj?.organizationId,
        isPrimary: true,
      };
      try {
        await memberService.updateMemberOrganization(_updatedMemberOrg);
      } catch (error) {
        console.error('error updating member organization', error);
      }
      handlePhoneInsert();
      let [updatedEnrollmentObj, allowThrough] = await checkBackendValidation();
      if (allowThrough) {
        setLoading(false);
        setAgentEnrollmentPageState({ [path]: true });
      } else {
        setLoading(false);
        setResults(updatedEnrollmentObj);
        setAgentEnrollmentPageState({ plans: true });
      }
    }
    if (path == 'personalInfo') {
      // console.log("path = personalINfo and spouse info: ", spouse);
      await saveSpouseInfo();
      setTimeLeft('About 1 minute left');
      setProgress(80);

      let builtAllocations = await buildSaveBackArray();
      //check for changes between new allocations and old before updating db
      let newAllocations = builtAllocations?.map((allocation) => {
        return {
          id: allocation.BeneficiaryId,
          amount: allocation.Amount,
        };
      });
      let oldAllocations = storedAllocations?.map((allocation) => {
        return {
          id: allocation.id,
          amount: allocation.amount,
        };
      });

      //check for changes between planIds selected before updating db- want to update even if allocation amounts do not change but new planId that requires bennies is selected
      let electedBennyRequiredPlanIds = buildBennyPlanIdArray();
      let changedPlanIds = false;

      if (
        plansWithSavedAllocations?.length !==
        electedBennyRequiredPlanIds?.length
      ) {
        changedPlanIds = true;
      } else {
        //create sets of each array to compare
        let setPlanIdsInDb = new Set(plansWithSavedAllocations);
        let setPlanIdsElected = new Set(electedBennyRequiredPlanIds);
        if (setPlanIdsInDb.size !== setPlanIdsElected.size)
          changedPlanIds = true;
        for (let planId of setPlanIdsElected) {
          if (!setPlanIdsInDb.has(planId)) changedPlanIds = true;
        }
      }
      // console.log('elected benny required plan ids', electedBennyRequiredPlanIds)
      // console.log('plan ids will allocations in db' , plansWithSavedAllocations)
      //if allocations exist and do not equal previous allocations, update db
      if (
        (builtAllocations.length > 0 &&
          JSON.stringify(newAllocations) !== JSON.stringify(oldAllocations)) ||
        changedPlanIds
      ) {
        //send for each planId
        for (let planId of electedBennyRequiredPlanIds) {
          //overWrite planId
          for (let allocation of builtAllocations) {
            allocation.PlanId = planId;
          }
          try {
            // console.log("attempting to save benny allocations agent enroll", builtAllocations)
            await enrollmentService.saveBeneficiaryAllocationForMember(
              builtAllocations,
              fullMemberObj.id
            ); //FOR MEMBER
          } catch (error) {
            console.log(
              'error saving beneficiary allocation for planid' +
                ' ' +
                builtAllocations[0]?.planId,
              error
            );
          }
        }
      }
      await getInitialBeneficiaries();
      setLoading(false);
      setAgentEnrollmentPageState({ [path]: true });
    }
    setLoading(false);
    props.getThisMember();
  };

  const [guid, setGuid] = useState();
  // Next Button Validation for Plan Selections
  const [planInfoValidation, setPlanInfoValidation] = useState(true);
  const [benRequired, setBenRequired] = useState(false);
  const [spouseRatesRequired, setSpouseRatesRequired] = useState(false);
  const [laddSelectionMade, setLaddSelectionMade] = useState(false);
  useEffect(() => {
    //If there's no ladd plan, set this to true to allow validation
    if (results && !results.lifeADDPlan) {
      // console.log("no life plan");
      setLaddSelectionMade(true);
    }
  }, [results?.lifeADDPlan]);

  useEffect(() => {
    // Setting valid selections if STD, LTD, or accident is elected or declined, but LADD is saving 0 for declined so this logic wont work
    //if std plan exists AND value has been selected
    let _stdPlansValidated = false;
    let _ltdPlansValidated = false;
    let _laddPlansValidated = false;
    let _accidentPlansValidated = false;
    let _cancerPlansValidated = false;
    let _addPlansValidated = false;
    let _hospitalPlansValidated = false;
    if (results?.stdPlan) {
      selections.std
        ? (_stdPlansValidated = false)
        : (_stdPlansValidated = true);
    } else _stdPlansValidated = false;
    //if ltd plan exists AND value has been selected
    if (results?.ltdPlan) {
      selections.ltd
        ? (_ltdPlansValidated = false)
        : (_ltdPlansValidated = true);
    } else _ltdPlansValidated = false;

    //if accident plans exist
    //accident plan not required, but if a spouse, dependent, or family is selected, must fill out spouse and dependent info

    if (results?.accidentPlan) {
      //if member and dependent(s) selected must fill out dependent info
      if (
        selections.accident &&
        accidentSelection[0] === 'member and dependents'
      ) {
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob
              ) {
                _accidentPlansValidated = false;
              } else {
                _accidentPlansValidated = true;
              }
            });
      }

      //if member and spouse selected, must fill out spouse info
      if (selections.accident && accidentSelection[0] === 'member and spouse') {
        spouse.firstName?.length > 0 &&
        spouse.lastName?.length > 0 &&
        spouse.dateOfBirth &&
        JSON.stringify(spouse.dateOfBirth).length > 2
          ? (_accidentPlansValidated = false)
          : (_accidentPlansValidated = true);
      }

      // if family selected, must fill out spouse and dependent info
      if (selections.accident && accidentSelection[0] === 'family') {
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                spouse.firstName?.length > 0 &&
                spouse.lastName?.length > 0 &&
                spouse.dateOfBirth &&
                JSON.stringify(spouse.dateOfBirth).length > 2
              ) {
                _accidentPlansValidated = false;
              } else {
                _accidentPlansValidated = true;
              }
            });
      }

      //if member only option is selected
      if (
        accidentSelection &&
        (accidentSelection[0] === 'member' || !accidentSelection[0])
      ) {
        selections.accident
          ? (_accidentPlansValidated = false)
          : (_accidentPlansValidated = true);
      }
    } else _accidentPlansValidated = false;

    //if accident plan not selected
    if (!selections.accident) _accidentPlansValidated = false;

    //if cancer plans exist
    //cancer plan not required, but if a spouse, dependent, or family is selected, must fill out spouse and dependent info
    if (results?.cancerPlan) {
      //if member and dependent(s) selected must fill out dependent info
      if (selections.cancer && cancerSelection[0] === 'member and dependents') {
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob
              ) {
                _cancerPlansValidated = false;
              } else {
                _cancerPlansValidated = true;
              }
            });
      }

      //if member and spouse selected, must fill out spouse info
      if (selections.cancer && cancerSelection[0] === 'member and spouse') {
        spouse.firstName?.length > 0 &&
        spouse.lastName?.length > 0 &&
        spouse.dateOfBirth &&
        JSON.stringify(spouse.dateOfBirth).length > 2
          ? (_cancerPlansValidated = false)
          : (_cancerPlansValidated = true);
      }

      // if family selected, must fill out spouse and dependent info
      if (selections.cancer && cancerSelection[0] === 'family') {
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                spouse.firstName?.length > 0 &&
                spouse.lastName?.length > 0 &&
                spouse.dateOfBirth &&
                JSON.stringify(spouse.dateOfBirth).length > 2
              ) {
                _cancerPlansValidated = false;
              } else {
                _cancerPlansValidated = true;
              }
            });
      }

      // if family selected, and only member or family options available must fill out spouse and dependent info
      if (selections.cancer && cancerSelection[0] === 'familyOptional') {
        let spouseFilledOut = false;
        let allDepsFilledOut = false;
        if (
          spouse.firstName?.length > 0 &&
          spouse.lastName?.length > 0 &&
          spouse.dateOfBirth &&
          JSON.stringify(spouse.dateOfBirth).length > 2
        ) {
          spouseFilledOut = true;
        }
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob
              ) {
                allDepsFilledOut = true;
              } else {
                allDepsFilledOut = false;
              }
            });
        if (!spouseFilledOut && !allDepsFilledOut) _cancerPlansValidated = true;
        else _cancerPlansValidated = false;
      }

      //if member only option is selected
      if (
        cancerSelection &&
        (cancerSelection[0] === 'member' || !cancerSelection[0])
      ) {
        selections.cancer
          ? (_cancerPlansValidated = false)
          : (_cancerPlansValidated = true);
      } else _cancerPlansValidated = false;

      //no cancer plan selected
      if (!selections.cancer) _cancerPlansValidated = false;
    }

    //add plan not required, but if a spouse, dependent, or family is selected, must fill out spouse and dependent info
    if (results?.addPlan) {
      //if member and dependent(s) selected must fill out dependent info
      if (selections.add && addSelection.includes('member and dependents')) {
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob
              ) {
                _addPlansValidated = false;
              } else {
                _addPlansValidated = true;
              }
            });
      }

      //if member and spouse selected, must fill out spouse info
      if (selections.add && addSelection.includes('member and spouse')) {
        spouse.firstName?.length > 0 &&
        spouse.lastName?.length > 0 &&
        spouse.dateOfBirth &&
        JSON.stringify(spouse.dateOfBirth).length > 2
          ? (_addPlansValidated = false)
          : (_addPlansValidated = true);
      }

      // if family selected, must fill out spouse and dependent info
      if (selections.add && addSelection.includes('family')) {
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                spouse.firstName?.length > 0 &&
                spouse.lastName?.length > 0 &&
                spouse.dateOfBirth &&
                JSON.stringify(spouse.dateOfBirth).length > 2
              ) {
                _addPlansValidated = false;
              } else {
                _addPlansValidated = true;
              }
            });
      }

      // if family selected, and only member or family options available must fill out spouse and dependent info
      if (selections.add && addSelection.includes('familyOptional')) {
        let spouseFilledOut = false;
        let allDepsFilledOut = false;
        if (
          spouse.firstName?.length > 0 &&
          spouse.lastName?.length > 0 &&
          spouse.dateOfBirth &&
          JSON.stringify(spouse.dateOfBirth).length > 2
        ) {
          spouseFilledOut = true;
        }
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob
              ) {
                allDepsFilledOut = true;
              } else {
                allDepsFilledOut = false;
              }
            });
        if (!spouseFilledOut && !allDepsFilledOut) _addPlansValidated = true;
        else _addPlansValidated = false;
      }

      let nothingSelected = true;
      if (addSelection?.length > 0) {
        for (let selection of addSelection) {
          if (selection != null) nothingSelected = false;
        }
      }
      //member only option selected
      if (addSelection?.includes('member') || nothingSelected) {
        selections.add
          ? (_addPlansValidated = false)
          : (_addPlansValidated = true);
      } else _addPlansValidated = false;
    }
    //no plan selected
    if (!selections.add) _addPlansValidated = false;

    //if a spouse, dependent, or family is selected, must fill out spouse and dependent info
    if (results?.hospitalPlan) {
      //if member and dependent(s) selected must fill out dependent info
      if (
        selections.hospital &&
        hospitalSelection[0] === 'member and dependents'
      ) {
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob
              ) {
                _hospitalPlansValidated = false;
              } else {
                _hospitalPlansValidated = true;
              }
            });
      }

      //if member and spouse selected, must fill out spouse info
      if (selections.hospital && hospitalSelection[0] === 'member and spouse') {
        spouse.firstName?.length > 0 &&
        spouse.lastName?.length > 0 &&
        spouse.dateOfBirth &&
        JSON.stringify(spouse.dateOfBirth).length > 2
          ? (_hospitalPlansValidated = false)
          : (_hospitalPlansValidated = true);
      }

      // if family selected, must fill out spouse and dependent info
      if (selections.hospital && hospitalSelection[0] === 'family') {
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                spouse.firstName?.length > 0 &&
                spouse.lastName?.length > 0 &&
                spouse.dateOfBirth &&
                JSON.stringify(spouse.dateOfBirth).length > 2
              ) {
                _hospitalPlansValidated = false;
              } else {
                _hospitalPlansValidated = true;
              }
            });
      }

      // if family selected, and only member or family options available must fill out spouse and dependent info
      if (selections.hospital && hospitalSelection[0] === 'familyOptional') {
        let spouseFilledOut = false;
        let allDepsFilledOut = false;
        if (
          spouse.firstName?.length > 0 &&
          spouse.lastName?.length > 0 &&
          spouse.dateOfBirth &&
          JSON.stringify(spouse.dateOfBirth).length > 2
        ) {
          spouseFilledOut = true;
        }
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob
              ) {
                allDepsFilledOut = true;
              } else {
                allDepsFilledOut = false;
              }
            });
        if (!spouseFilledOut && !allDepsFilledOut)
          _hospitalPlansValidated = true;
        else _hospitalPlansValidated = false;
      }

      //member only option selected
      if (
        hospitalSelection &&
        (hospitalSelection[0] === 'member' || !hospitalSelection[0])
      ) {
        selections.hospital
          ? (_hospitalPlansValidated = false)
          : (_hospitalPlansValidated = true);
      } else _hospitalPlansValidated = false;
    }
    //no plan selected
    // if (!selections.hospital) _hospitalPlansValidated = false; //hospital is required, so we need them to select an option

    //Life plan section validation
    let _spouseInfoValidated = false;
    let _dependentInfoValidated = false;
    console.log('selections.....', selections);

    //check if spouse selected within LADD plan, if yes, require spouse firstName, lastName, dob before enabling 'next' button
    if (selections.ladd[1] > 0) {
      console.log(
        'spouse in validation',
        spouse,
        'json ...',
        JSON.stringify(spouse.dateOfBirth).length
      );
      spouse.firstName?.length > 0 &&
      spouse.lastName?.length > 0 &&
      spouse.dateOfBirth &&
      !spouse.dobError &&
      !spouse.ageError
        ? // &&
          // JSON.stringify(spouse.dateOfBirth).length > 2
          (_spouseInfoValidated = true)
        : (_spouseInfoValidated = false);
    } else if (selections.ladd[1] === 0) _spouseInfoValidated = true;

    // check if dependent selected within LADD plan, if yes, require dependent firstName, lastName, dob before enabling 'next' button
    if (selections.ladd[2] > 0) {
      _dependentInfoValidated = true;

      console.log('dep in validation', dependents);
      dependents &&
        dependents
          .filter((dep) => !dep.isDeleted)
          .forEach((dep) => {
            if (
              !(dep.firstName?.length > 0) ||
              !(dep.lastName?.length > 0) ||
              !dep.dob ||
              dep.dobError ||
              !dep.gender
            ) {
              _dependentInfoValidated = false;
            }
            // else {
            //   _dependentInfoValidated = false;
            // }
          });
    } else if (selections.ladd[2] === 0) _dependentInfoValidated = true;

    //recheck validations afer spouse/dependent info has changed to ensure selection from member, spouse, and dependent has been made, even if declined
    if (results?.lifeADDPlan) {
      laddSelectionMade && _spouseInfoValidated && _dependentInfoValidated
        ? (_laddPlansValidated = false)
        : (_laddPlansValidated = true);
    } else _laddPlansValidated = false;

    console.log('spouseInfoValidated', _spouseInfoValidated);
    console.log('dependentInfoValidated', _dependentInfoValidated);
    console.log('laddPlansValidated', _laddPlansValidated);
    console.log('stdPlansValidated', _stdPlansValidated);
    console.log('ltdPlansValidated', _ltdPlansValidated);

    //set the value of true (keep next button disabled - waiting for more information from user), or false (all required info and Next button is enabled)
    //if all the above plans meet validation (are set to false), set total validation value of false, else true (disabled=true) if all requirements not met

    console.log(
      'all validation flags',
      _stdPlansValidated,
      _ltdPlansValidated,
      _laddPlansValidated,
      _accidentPlansValidated,
      _cancerPlansValidated,
      _addPlansValidated,
      _hospitalPlansValidated,
      '\nsposeinfo, dependent info',
      _spouseInfoValidated,
      _dependentInfoValidated
    );
    if (
      !_stdPlansValidated &&
      !_ltdPlansValidated &&
      !_laddPlansValidated &&
      !_accidentPlansValidated &&
      !_cancerPlansValidated &&
      !_addPlansValidated &&
      !_hospitalPlansValidated
    ) {
      setPlanInfoValidation(false);
    } else setPlanInfoValidation(true);

    // Checks if beneficiaries are required before pushing to benny page
    if (selections.ladd[0] !== 0) {
      setBenRequired(true);
    } else {
      setBenRequired(false);
    }
  }, [
    selections,
    laddSelectionMade,
    spouse,
    spouse?.dobError,
    spouse?.ageError,
    dependents,
    dependents.map((d) => d?.dobError),
    accidentSelection,
    cancerSelection,
    addSelection,
    hospitalSelection,
  ]);

  console.log('planInfoValidation', planInfoValidation);
  // Next Button Validation for Beneficiaries
  const [benValidated, setBenValidated] = useState(true);
  const [addedBennyNotSaved, setAddedBennyNotSaved] = useState(false);

  useEffect(() => {
    let anyTrue = false;
    let percentTotalCheck = 0;
    let contingentAnyTrue = false;
    let contingentPercentTotalCheck = 0;
    primaryCheckboxes?.length > 0 &&
      primaryCheckboxes.map((ben) => {
        if (ben.checked == true) {
          anyTrue = true;
          percentTotalCheck += ben.amount;
        }
        // console.log('benny in validator',ben)
      });
    contingentCheckboxes?.length > 0 &&
      contingentCheckboxes.map((ben) => {
        if (ben.checked == true) {
          contingentAnyTrue = true;
          contingentPercentTotalCheck += ben.amount;
        }
        // console.log('benny in validator',ben)
      });
    let validated = false;
    if (anyTrue) {
      validated = percentTotalCheck == 100;
    }
    if (anyTrue && contingentAnyTrue) {
      validated =
        percentTotalCheck == 100 && contingentPercentTotalCheck == 100;
    }
    if (validated) {
      setBenValidated(false);
    } else {
      setBenValidated(true);
    }
    // if (anyTrue == false) {
    //   setBenValidated(true);
    // } else {
    //   setBenValidated(false);
    // }
    // console.log(primaryCheckboxes)
  }, [primaryCheckboxes, contingentCheckboxes]);

  // const [position, setPosition] = useState("sticky")

  //PHONE LOGIC
  const [phones, setPhones] = useState([]);
  const [primaryPhoneFromWrapper, setPrimaryPhoneFromWrapper] = useState({});
  const [secondaryPhoneFromWrapper, setSecondaryPhoneFromWrapper] = useState(
    {}
  );

  const getPhones = async () => {
    let _phones;
    try {
      _phones = await memberService.getPhonesForMember(fullMemberObj.id);
    } catch (error) {
      console.error('error getting phones in agent wrapper', error);
    }

    setPhones(_phones);

    let preferredNumber = _phones?.find((phone) => {
      if (phone.isPreferred) return phone;
    });

    let secondPhoneNumber = _phones?.find((phone) => {
      if (phone.isPreferred === false) {
        return phone;
      }
    });
    setPrimaryPhoneFromWrapper(preferredNumber ? preferredNumber : {});
    setSecondaryPhoneFromWrapper(secondPhoneNumber ? secondPhoneNumber : {});
  };

  const [phonesLoaded, setPhonesLoaded] = useState(false);

  useEffect(() => {
    if (!phonesLoaded && fullMemberObj.id) {
      getPhones();
      setPhonesLoaded(true);
    }
  }, [fullMemberObj]);
  // console.log("PHONES", phones)

  const handlePhoneInsert = async () => {
    //if primary or secondary numbers already exist, update these numbers w/ phone id
    // console.log("PRIMARY PHONE FROM AGENT WRAPPER", primaryPhoneFromWrapper);
    // console.log(
    //   "Secondary PHONE FROM AGENT WRAPPER",
    //   secondaryPhoneFromWrapper
    // );
    if (primaryPhoneFromWrapper && primaryPhoneFromWrapper.id) {
      try {
        await memberService.updatePhoneForMember(primaryPhoneFromWrapper);
      } catch (error) {
        console.error('error updating primary/mobile phone', error);
      }
    }
    if (secondaryPhoneFromWrapper && secondaryPhoneFromWrapper.id) {
      try {
        await memberService.updatePhoneForMember(secondaryPhoneFromWrapper);
      } catch (error) {
        console.error('error updating secondary/home phone', error);
      }
    }
    //  //if primary or secondary numbers don't exist, create these numbers w/ NO phone id
    if (primaryPhoneFromWrapper && !primaryPhoneFromWrapper.id) {
      try {
        await memberService.insertPhoneForMember(primaryPhoneFromWrapper);
      } catch (error) {
        console.error('error inserting a new primary/mobile phone', error);
      }
    }
    if (secondaryPhoneFromWrapper && !secondaryPhoneFromWrapper.id) {
      try {
        await memberService.insertPhoneForMember(secondaryPhoneFromWrapper);
      } catch (error) {
        console.error('error inserting a new secondary/home phone', error);
      }
    }
    getPhones();
  };

  const [timeLeft, setTimeLeft] = useState('About 3 minutes left');
  const [progress, setProgress] = useState(20);

  const [agentEnrollmentPageState, setAgentEnrollmentPageState] = useState({
    allEnrollments: false,
    // groupPlanId:null,
    calculator: true,
    plans: false,
    beneficiaries: false,
    personalInfo: false,
    payment: false,
    complete: false,
  });

  useEffect(() => {
    if (agentEnrollmentPageState.plans) {
      props.setTabValue(0);
    }
    if (agentEnrollmentPageState.beneficiaries) {
      props.setTabValue(1);
    }
    if (agentEnrollmentPageState.personalInfo) {
      props.setTabValue(2);
    }
    if (agentEnrollmentPageState.payment) {
      props.setTabValue(3);
    }
  }, [agentEnrollmentPageState]);

  //useEffect(() => {
  //  if (agentEnrollmentPageState?.beneficiaries && fullMemberObj.id > 0 && results) {
  //    setLoading(true)
  //    getInitialBeneficiaries();
  //    setLoading(false)
  //  }
  //}, [agentEnrollmentPageState?.beneficiaries, fullMemberObj.id]);

  // Saving from left side of agent portal
  const handleSaveCurrentPage = async () => {
    // console.log("hitting save");
    // console.log(agentEnrollmentPageState);
    if (agentEnrollmentPageState.calculator) {
    }
    if (agentEnrollmentPageState.plans) {
      // Save plan info
      await saveLadd();
      await saveSpouseInfo();
      await saveDependents();
      await updateResults();
      await props.getThisMember();
      props.setSave(false);
    }
    if (agentEnrollmentPageState.beneficiaries) {
      // Save Benny Info
      let builtAllocations = await buildSaveBackArray();

      //Check for isBeneficiary
      let plans = [];
      for (let [key, value] of Object.entries(results)) {
        if (key.slice(key.length - 4, key.length) === 'Plan' && value != null) {
          plans.push(value);
        }
      }
      let electedBennyRequiredPlanIds = [];
      for (let plan of plans) {
        //check for user selection (decline or otherwise)
        //ladd doesnt have selectedOptionId, but has totalPremiumAmount on plan level
        if (plan?.totalPremiumAmount > 0) {
          if (plan.isBeneficiaryEnabled)
            electedBennyRequiredPlanIds.push(plan.planId);
        } else if (
          plan.selectedOptionId != 0 &&
          plan?.options &&
          plan.options.length > 0
        ) {
          for (let option of plan.options) {
            //check that the selected option isnt a decline
            if (
              option.isDecline &&
              plan.selectedOptionId != option.memberOptionId
            ) {
              if (plan.isBeneficiaryEnabled)
                electedBennyRequiredPlanIds.push(plan.planId);
            }
          }
        }
      }
      console.log('electedBennyRequiredPlanIds', electedBennyRequiredPlanIds);

      if (builtAllocations.length > 0) {
        //send for each planId
        for (let planId of electedBennyRequiredPlanIds) {
          //overWrite planId
          for (let allocation of builtAllocations) {
            allocation.PlanId = planId;
          }
          try {
            await enrollmentService.saveBeneficiaryAllocationForMember(
              builtAllocations,
              fullMemberObj.id
            ); //FOR MEMBER
          } catch (error) {
            console.log(
              'error saving beneficiary allocation for planid' +
                ' ' +
                builtAllocations[0]?.planId,
              error
            );
          }
        }
      }
      props.setSave(false);
    }
    if (agentEnrollmentPageState.personalInfo) {
      // Save Personal Info
      // console.log("Save Personal Ifno");
      try {
        await memberService.updateForMember(fullMemberObj.id, fullMemberObj);
        await props.getThisMember();
      } catch (error) {
        console.log('error updating member', error);
      }
      props.setSave(false);
    }
    if (agentEnrollmentPageState.payment) {
      // Save payment
    }
    props.setOpenEnrollment(false);
  };

  useEffect(() => {
    if (props.save) {
      handleSaveCurrentPage();
    }
  }, [props.save]);

  useEffect(() => {
    if (props.nav.click) {
      if (props.nav.page == 'calculator') {
        setAgentEnrollmentPageState({
          ...agentEnrollmentPageState,
          allEnrollments: false,
          // groupPlanId:null,
          calculator: true,
          plans: false,
          beneficiaries: false,
          personalInfo: false,
          payment: false,
          complete: false,
          progressSaved: false,
        });
      }
      if (props.nav.page == 'plans') {
        setAgentEnrollmentPageState({
          ...agentEnrollmentPageState,
          allEnrollments: false,
          // groupPlanId:null,
          calculator: false,
          plans: true,
          beneficiaries: false,
          personalInfo: false,
          payment: false,
          complete: false,
          progressSaved: false,
        });
      }
      if (props.nav.page == 'beneficiaries') {
        setAgentEnrollmentPageState({
          ...agentEnrollmentPageState,
          allEnrollments: false,
          // groupPlanId:null,
          calculator: false,
          plans: false,
          beneficiaries: true,
          personalInfo: false,
          payment: false,
          complete: false,
          progressSaved: false,
        });
      }
      if (props.nav.page == 'personalInfo') {
        setAgentEnrollmentPageState({
          ...agentEnrollmentPageState,
          allEnrollments: false,
          // groupPlanId:null,
          calculator: false,
          plans: false,
          beneficiaries: false,
          personalInfo: true,
          payment: false,
          complete: false,
          progressSaved: false,
        });
      }
      if (props.nav.page == 'payment') {
        setAgentEnrollmentPageState({
          ...agentEnrollmentPageState,
          allEnrollments: false,
          // groupPlanId:null,
          calculator: false,
          plans: false,
          beneficiaries: false,
          personalInfo: false,
          payment: true,
          complete: false,
          progressSaved: false,
        });
      }
      props.setNav({
        ...props.nav,
        click: false,
        page: '',
      });
    }
  }, [props.nav.click]);

  const [memberAgent, setMemberAgent] = useState();
  const [localMemberAgentInfo, setLocalMemberAgentInfo] = useState();
  const getMemberAgent = async () => {
    try {
      let _memberAgent = await memberService.getMemberAgentForMember(
        fullMemberObj?.id
      );
      if (_memberAgent) {
        setMemberAgent(_memberAgent);
        // console.log(_memberAgent)
        setLocalMemberAgentInfo(_memberAgent);
      } else {
        setMemberAgent(false);
        setLocalMemberAgentInfo(false);
      }
    } catch (error) {
      console.log('error grabbing memberAgent', error);
      setMemberAgent(false);
      setLocalMemberAgentInfo(false);
    }
  };
  useEffect(() => {
    if (fullMemberObj?.id) getMemberAgent();
  }, [fullMemberObj?.id]);

  const [scrollAtBottom, setScrollAtBottom] = useState(false);

  //this top level NAW state is used for the sticky progress bar, which has the next button
  const [notActivelyWorking, setNotActivelyWorking] = useState(null);

  const handleNawExit = async () => {
    // console.log('NAW EXIT')
    if (agentEnrollmentPageState.personalInfo) {
      try {
        await memberService.updateForMember(fullMemberObj.id, fullMemberObj);
      } catch (error) {
        console.log('error updating member', error);
      }
      handlePhoneInsert();
      setLoading(false);
      // setAgentEnrollmentPageState({ progressSaved: true });
      props.setActivePage(0);
    } else {
      props.setActivePage(0);
    }
  };

  useEffect(() => {
    // console.log('fullMemberObj work status change',fullMemberObj.workStatusId)
    if (fullMemberObj.workStatusId > 0 && fullMemberObj.workStatusId != 1) {
      setNotActivelyWorking(true);
    } else {
      setNotActivelyWorking(false);
    }
  }, [fullMemberObj.workStatusId]);

  const [nawReason, setNawReason] = useState(null);

  // Next Button Validation for Personal Info
  const [personalValidated, setPersonalValidated] = useState(true);

  useEffect(() => {
    let mobilePhone = primaryPhoneFromWrapper?.number;
    if (
      fullMemberObj &&
      fullMemberObj.firstName !== '' &&
      fullMemberObj.gender > 0 &&
      fullMemberObj.street1 !== '' &&
      fullMemberObj.city !== '' &&
      fullMemberObj.state !== '' &&
      fullMemberObj.zipCode !== '' &&
      fullMemberObj.workStatusId > 0 &&
      fullMemberObj.jobTitle !== null &&
      fullMemberObj.jobTitle !== '' &&
      mobilePhone &&
      mobilePhone.length === 10
    ) {
      setPersonalValidated(false);
    } else setPersonalValidated(true);
  }, [fullMemberObj, primaryPhoneFromWrapper]);

  //format dob to be used in other components throughout enrollment
  const formatDate = (rawDate) => {
    // console.log('formatDate',rawDate)
    // if (rawDate?.length < 11) rawDate = new Date(rawDate).toISOString();
    let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/);
    let date = new Date();
    date.setUTCFullYear(+yyyy);
    date.setUTCDate(+dd);
    date.setUTCMonth(mm - 1);
    date.setUTCHours(+hh);
    date.setUTCMinutes(+m + date.getTimezoneOffset());
    date.setUTCSeconds(+s);
    // console.log('formatDate after',date)
    return date;
  };

  //The rules being checked for this are all in the backend, hence, backendValidation
  const [backendValidation, setBackendValidation] = useState(null);
  const checkBackendValidation = async () => {
    let _backendValidation = false;
    let validationReturn;
    try {
      validationReturn = await enrollmentService.validateEnrollmentForMember(
        fullMemberObj.id
      );
    } catch (error) {
      console.log('error checking backend validation', error);
    }

    if (validationReturn?.length > 0) {
      var latestPlan = validationReturn.find(
        (groupPlan) => groupPlan.groupPlanId === results.groupPlanId
      );
      if (!latestPlan?.hasIneligibleItemSelected) _backendValidation = true;
    }

    setBackendValidation(_backendValidation);
    // setResults(validationReturn);
    return [latestPlan, _backendValidation];
  };

  return (
    <>
      {loading && (
        <>
          <InfinityLoader
            style={{
              position: 'absolute',
              zIndex: 5,
              width: '-webkit-fill-available',
              // height: '-webkit-fill-available',
              // backgroundColor: '#00000020',
              // backdropFilter: 'blur(5px)',
            }}
          />
          <style>
            {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:relative;
                     margin: 250px auto 0px 120px;
                     
                  }
               `}
          </style>
        </>
      )}
      <Box
        className="flex-row col-8"
        id="enrollment-container"
        flex={1}
        sx={{
          display: 'block',
          height: 'auto',
          margin: 'auto',
          overflowX: 'hidden',
          filter: loading ? 'blur(5px)' : '',
          backgroundColor: loading ? '#00000005' : '',
          bgcolor: 'background.default',
          color: 'text.primary',
          padding: '0px',
        }}
      >
        <enrollmentContext.Provider value={contextProps}>
          {isEnrolled ? (
            <>
              <EnrollmentComplete
                agentEnroller={true}
                fullMemberObj={fullMemberObj}
                guid={guid}
                results={results}
                notActivelyWorking={notActivelyWorking}
              />
              {/* <PoweredBy /> */}
            </>
          ) : (
            <>
              {agentEnrollmentPageState.complete && (
                <>
                  <EnrollmentComplete
                    agentEnroller={true}
                    fullMemberObj={fullMemberObj}
                    guid={guid}
                    results={results}
                    notActivelyWorking={notActivelyWorking}
                  />
                  {/* <PoweredBy /> */}
                </>
              )}

              {agentEnrollmentPageState.payment && (
                <>
                  <EnrollmentPayment
                    getThisMember={props.getThisMember}
                    guid={guid}
                    setGuid={setGuid}
                    results={results}
                    selections={selections}
                    runningTotal={runningTotal}
                    updateResults={updateResults}
                    userInfo={userInfo}
                    enrollmentId={results?.enrollmentId}
                    // expanded={expanded} setExpanded={setExpanded}5
                    // showPayment={showPayment} setShowPayment={setShowPayment}
                    fullMemberObj={fullMemberObj}
                    agentEnroller={true}
                    agentEnrollmentPageState={agentEnrollmentPageState}
                    setAgentEnrollmentPageState={setAgentEnrollmentPageState}
                    save={props.save}
                    setSave={props.setSave}
                    setOpenEnrollment={props.setOpenEnrollment}
                    refreshEnrollments={refreshEnrollments}
                    refreshEnrollment={props.getOneEnrollment}
                    setLoading={setLoading}
                    nawReason={nawReason}
                    getPaymentSchedule={props?.getPaymentSchedule}
                  />
                  {/* <StickyProgressTotal runningTotal={runningTotal} nextClickHandler={handleNextClick} selections={selections} nextLocation={'success'} /> */}
                </>
              )}

              {agentEnrollmentPageState.personalInfo && (
                <>
                  <EnrollmentPersonalInfo
                    fullMemberObj={fullMemberObj}
                    setFullMemberObj={setFullMemberObj}
                    results={results}
                    phones={phones}
                    setPhones={setPhones}
                    primaryPhoneFromWrapper={primaryPhoneFromWrapper}
                    setPrimaryPhoneFromWrapper={setPrimaryPhoneFromWrapper}
                    secondaryPhoneFromWrapper={secondaryPhoneFromWrapper}
                    setSecondaryPhoneFromWrapper={setSecondaryPhoneFromWrapper}
                    agentEnroller={true}
                    agentEnrollmentPageState={agentEnrollmentPageState}
                    setAgentEnrollmentPageState={setAgentEnrollmentPageState}
                    nextLocation={'payment'}
                    lastLocation={benRequired ? 'beneficiaries' : 'plans'}
                    allowBack={true}
                    nextClickHandler={handleNextClick}
                    // notActivelyWorking={notActivelyWorking}
                    // handleNawExit={handleNawExit}
                    nextValidation={personalValidated}
                    permissionsEnabler={props.permissionsEnabler}
                    fullSocial={fullSocial}
                    setRequire9DigitSocial={setRequire9DigitSocial}
                    require9DigitSocial={require9DigitSocial}
                    checkBackendValidation={checkBackendValidation}
                    nawReason={nawReason}
                    setNawReason={setNawReason}
                  />
                  {/* <StickyProgressTotal agentEnroller={true}
                              agentEnrollmentPageState={agentEnrollmentPageState}
                              setAgentEnrollmentPageState={setAgentEnrollmentPageState} runningTotal={runningTotal} nextValidation={personalValidated} nextClickHandler={handleNextClick}
                              notActivelyWorking={notActivelyWorking}
                              handleNawExit={handleNawExit}
                              timeLeft={timeLeft} progress={progress}
                              // selections={selections} 
                              nextLocation={'payment'} lastLocation={benRequired ? 'beneficiaries' : 'plans'} allowBack={true} /> */}
                </>
              )}

              {agentEnrollmentPageState.beneficiaries && (
                <>
                  <EnrollmentBeneficiaries
                    primaryCheckboxes={primaryCheckboxes}
                    setPrimaryCheckboxes={setPrimaryCheckboxes}
                    contingentCheckboxes={contingentCheckboxes}
                    setContingentCheckboxes={setContingentCheckboxes}
                    primaryBeneficiaries={primaryBeneficiaries}
                    setPrimaryBeneficiaries={setPrimaryBeneficiaries}
                    contingentBeneficiaries={contingentBeneficiaries}
                    setContingentBeneficiaries={setContingentBeneficiaries}
                    beneficiariesInDB={beneficiariesInDB}
                    setBeneficiariesInDB={setBeneficiariesInDB}
                    potentialBeneficiaries={potentialBeneficiaries}
                    setPotentialBeneficiaries={setPotentialBeneficiaries}
                    storedAllocations={storedAllocations}
                    setStoredAllocations={setStoredAllocations}
                    getInitialBeneficiaries={getInitialBeneficiaries}
                    primaryBennyAdd={primaryBennyAdd}
                    setPrimaryBennyAdd={setPrimaryBennyAdd}
                    contingentBennyAdd={contingentBennyAdd}
                    primaryBennyEdit={primaryBennyEdit}
                    contingentBennyEdit={contingentBennyEdit}
                    setPrimaryBennyEdit={setPrimaryBennyEdit}
                    setContingentBennyEdit={setContingentBennyEdit}
                    setContingentBennyAdd={setContingentBennyAdd}
                    results={results}
                    setResults={setResults}
                    updateResults={updateResults}
                    userInfo={userInfo}
                    benficiariesObj={benficiariesObj}
                    setBeneficiariesObj={setBeneficiariesObj}
                    deleteBeneficiary={deleteBeneficiary}
                    agentEnroller={true}
                    fullMemberObj={fullMemberObj}
                    nextClickHandler={handleNextClick}
                    nextLocation={'personalInfo'}
                    lastLocation={'plans'}
                    allowBack={true}
                    agentEnrollmentPageState={agentEnrollmentPageState}
                    setAgentEnrollmentPageState={setAgentEnrollmentPageState}
                    setLoading={setLoading}
                    spouse={spouse}
                    setSpouse={setSpouse}
                    saveSpouseInfo={saveSpouseInfo}
                    genderTypes={genderTypes}
                    formatDate={formatDate}
                    setAddedBennyNotSaved={setAddedBennyNotSaved}
                    // manageContingentCheckboxChanges={manageContingentCheckboxChanges}
                  />
                  {/* <PoweredBy/> */}
                  {/* <StickyProgressTotal agentEnroller={true}
                              agentEnrollmentPageState={agentEnrollmentPageState}
                              setAgentEnrollmentPageState={setAgentEnrollmentPageState} runningTotal={runningTotal} nextValidation={benValidated} nextClickHandler={handleNextClick}
                              timeLeft={timeLeft} progress={progress}
                              // selections={selections} 
                              nextLocation={'personalInfo'} lastLocation={'plans'} allowBack={true} /> */}
                </>
              )}

              {agentEnrollmentPageState.plans && (
                <>
                  <EnrollmentPlans
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    results={results}
                    setResults={setResults}
                    updateResults={updateResults}
                    recommendations={recommendations}
                    setRecomendations={setRecomendations}
                    selections={selections}
                    setSelections={setSelections}
                    selectionRec={selectionRec}
                    selectionHandler={selectionHandler}
                    calculated={calculated}
                    runningTotal={runningTotal}
                    fullMemberObj={fullMemberObj}
                    dependents={dependents}
                    setDependents={setDependents}
                    saveDependents={saveDependents}
                    spouse={spouse}
                    setSpouse={setSpouse}
                    spouseRatesLoading={spouseRatesLoading}
                    genderTypes={genderTypes}
                    scrollAtBottom={scrollAtBottom}
                    setScrollAtBottom={setScrollAtBottom}
                    refreshEnrollments={refreshEnrollments}
                    agentEnroller={true}
                    agentEnrollmentPageState={agentEnrollmentPageState}
                    nextClickHandler={handleNextClick}
                    setAgentEnrollmentPageState={setAgentEnrollmentPageState}
                    setEnrollmentCreated={setEnrollmentCreated}
                    setGuid={setGuid}
                    nextValidation={planInfoValidation}
                    setLaddSelectionMade={setLaddSelectionMade}
                    accidentSelection={accidentSelection}
                    setAccidentSelection={setAccidentSelection}
                    cancerSelection={cancerSelection}
                    setCancerSelection={setCancerSelection}
                    addSelection={addSelection}
                    setAddSelection={setAddSelection}
                    hospitalSelection={hospitalSelection}
                    setHospitalSelection={setHospitalSelection}
                    handleLaddSaveAndContinue={handleLaddSaveAndContinue}
                    saveLadd={saveLadd}
                    backendValidation={backendValidation}
                    setBackendValidation={setBackendValidation}
                  />
                  {/* <PoweredBy/> */}
                  {/* <StickyProgressTotal agentEnroller={true}
                              agentEnrollmentPageState={agentEnrollmentPageState}
                              setAgentEnrollmentPageState={setAgentEnrollmentPageState} runningTotal={runningTotal} nextValidation={planInfoValidation} nextClickHandler={handleNextClick}
                              timeLeft={timeLeft} progress={progress}
                              position={'fixed'}
                              // selections={selections} 
                              nextLocation={'beneficiaries'} allowBack={false} /> */}
                  {/* <PoweredBy/> */}
                </>
              )}

              {agentEnrollmentPageState.progressSaved && (
                <>
                  <EnrollmentProgressSaved
                    agentEnroller={true}
                    fullMemberObj={fullMemberObj}
                    guid={guid}
                    results={results}
                  />
                  {/* <PoweredBy/> */}
                  {/* <StickyProgressTotal
                    runningTotal={runningTotal}
                    nextValidation={planInfoMissing}
                    nextClickHandler={handleNextClick}
                    timeLeft={timeLeft}
                    progress={progress}
                    scrollAtBottom={scrollAtBottom}
                    // selections={selections}
                    nextLocation={"beneficiaries"}
                    allowBack={false}
                  /> */}
                  {/* <PoweredBy/> */}
                </>
              )}

              {agentEnrollmentPageState.calculator && (
                <>
                  <ExternalEnrollmentCalculator
                    refreshMember={props.getThisMember}
                    save={props.save}
                    setSave={props.setSave}
                    setOpenEnrollment={props.setOpenEnrollment}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    results={results}
                    setResults={setResults}
                    setRecomendations={setRecomendations}
                    selections={selections}
                    selectionRec={selectionRec}
                    selectionHandler={selectionHandler}
                    setCalculated={setCalculated}
                    setLoading={setLoading}
                    dependents={dependents}
                    saveDependents={saveDependents}
                    agentEnroller={true}
                    fullMemberObj={fullMemberObj}
                    setFullMemberObj={setFullMemberObj}
                    memberAgent={memberAgent}
                    setMemberAgent={setMemberAgent}
                    localMemberAgentInfo={localMemberAgentInfo}
                    setLocalMemberAgentInfo={setLocalMemberAgentInfo}
                    getMemberAgent={getMemberAgent}
                    agentEnrollmentPageState={agentEnrollmentPageState}
                    setAgentEnrollmentPageState={setAgentEnrollmentPageState}
                    selectedEnrollment={props.singleEnrollment}
                    handleNawExit={handleNawExit}
                  />
                  {/* <StickyProgressTotal runningTotal={runningTotal} nextClickHandler={handleNextClick} selectiotCns={selections} /> */}
                </>
              )}
            </>
          )}
        </enrollmentContext.Provider>
      </Box>
      {/* {openEnrollment ?
            :
            <>
            </>
         } */}
    </>
  );
}
