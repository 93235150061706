import './App.css';
// import './components/app-marketing/external-landing/ExternalLanding.module.css';
import './components/app-external/external-resetpassword/ExternalResetPassword.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import React, { useEffect, useState, createContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { TopLevelRoutes, UnprotectedRoutes } from './Routes.js';
// Note: When I try to remove these unused imports, the bootstrap import is getting mad and throwing off margins

//SERVICES
import authService from './services/auth-service';
import organizationService from './services/organization-service';

//OUR COMPONENTS
import NotFound from './components/notfound/NotFound';

//EXTERNAL MEMBER SITE
import ExternalLoginAccount from './components/app-external/external-login-account/ExternalLoginAccount';
import ExternalRegister from './components/app-external/external-register/ExternalRegister';
import ExternalResetPassword from './components/app-external/external-resetpassword/ExternalResetPassword';
import ExternalConfirmEmail from './components/app-external/external-confirmemail/ExternalConfirmEmail';
import ExternalEnrollments from './components/app-external/external-enrollments/ExternalEnrollments';
import SingleEnrollmentWrapper from './components/app-external/external-enrollments/SingleEnrollmentWrapper';
import ExternalPrenoteFailure from './components/app-external/external-prenote-failure/ExernalPrenoteFailure';
import MemberPortal from './components/app-external/external-member-portal/MemberPortal.js';

//RATE CALCULATOR
import StandaloneRateCalculator from './components/standalone-rate-calculator/StandaloneRateCalculator';
import StandaloneRateCalculatorMCL from './components/standalone-rate-calculator-mcl/StandaloneRateCalculatorMCL';
import TestPage from './components/app-external/TestPage';

export let ColorModeContext = createContext();

export default function AppRouter() {
  let location = window.location;
  // console.log('location',location)

  let lightTheme = createTheme({
    palette: {
      mode: 'light',
      background: {
        default: '#FFFFFF',
        paper: '#F9F9F9',
        background: 'rgb(243, 245, 249);',
        border: 'rgba(224, 224, 224, 1)',
        another: '#F3F5F9',
        recommendation: '#E9F6F5',
      },

      primary: {
        main: '#195ffb',
        light: '#195ffb20',
        accent: '#195ffb',
        secureDataMsg: '#0335A5',
        grayInfoTip: 'rgba(0, 0, 0, 0.54)',
      },
      secondary: {
        main: '#5521c9',
        recommendationOutline: 'rgba(38, 166, 154, 1)',
      },
      text: {
        gray: '#49454F',
        lightGray: '#646464',
        recommendation: 'rgba(15, 66, 62, 1)',
      },
    },
    typography: {
      h1: {
        fontFamily: 'Poppins',
      },
      h2: {
        fontFamily: 'Poppins',
      },
      h3: {
        fontFamily: 'Poppins',
      },
      h6: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '20px!important',
        lineHeight: '32px',
        letterSpacing: '0.15px',
      },
      subtitle: {
        fontFamily: 'Poppins',
        lineHeight: '33px',
        fontSize: '24px',
        // "@media (min-width:600px)": {
        //   fontSize: "30px",
        // },
      },
      subtitle1: {
        fontFamily: 'Poppins',
        lineHeight: '33px',
        fontSize: '22px',
        '@media (min-width:600px)': {
          fontSize: '25px',
        },
      },
      subtitle2: {
        fontFamily: 'Poppins',
        lineHeight: '25px',
        fontSize: '20px',
        '@media (min-width:600px)': {
          fontSize: '22px',
        },
      },
      subtitle3: {
        fontFamily: 'Poppins',
        lineHeight: '25px',
        fontSize: '15px',
        '@media (min-width:600px)': {
          fontSize: '15px',
        },
        color: '#646464',
      },
      blueAccent: {
        fontFamily: 'Poppins',
        lineHeight: '45px',
        fontWeight: '700',
        color: '#195FFB',
        fontSize: '24px',
        '@media (min-width:600px)': {
          fontSize: '30px',
        },
      },
      blueAccentMed: {
        fontFamily: 'Archivo',
        lineHeight: '25px',
        fontWeight: '500',
        color: '#195FFB',
        fontSize: '25px',
        '@media (min-width:600px)': {
          fontSize: '25px',
        },
      },
      smallAccent: {
        fontFamily: 'Archivo',
        lineHeight: '25px',
        fontWeight: '800',
        color: '#000000',
        fontSize: '10px',
        '@media (min-width:600px)': {
          fontSize: '10px',
        },
      },
      overline: {
        fontFamily: 'Archivo',
        lineHeight: '25px',
        letterSpacing: '0',
        fontSize: '15px',
        '@media (min-width:600px)': {
          fontSize: '15px',
        },
      },
      body1: {
        fontFamily: 'Archivo',
      },
      body2: {
        fontFamily: 'Archivo',
        fontSize: '15px',
        '@media (min-width:600px)': {
          fontSize: '15px',
        },
        lineHeight: '25px',
        fontWeight: '500',
      },
      body3: {
        fontFamily: 'Archivo',
        fontSize: '15px',
        lineHeight: '25px',
        opacity: '87%',
        fontWeight: '600',
      },
      button: {
        fontFamily: 'Archivo',
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 100,
            fontFamily: 'Archivo',
            textTransform: 'none',
            fontSize: '1rem',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            textAlign: 'right',
          },
        },
      },
    },
  });

  let darkTheme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#1E1E1E',
        paper: '#1E1E1E',
        background: '#121212',
        border: 'rgba(81, 81, 81, 1)',
        dataGridEven: '#F9FaFB',
        another: '#F3F5F9',
        recommendation: '#E9F6F5',
      },
      primary: {
        main: '#8BAEFC',
        light: '#90caf950',
        accent: '#42A5F5',
        grayInfoTip: '#C4C4C4',
      },
      secondary: {
        main: '#5521c9',
        recommendationOutline: 'rgba(38, 166, 154, 1)',
      },
      text: {
        other: '#49454F',
        gray: '#C4C4C4',
        recommendation: 'rgba(15, 66, 62, 1)',
      },
    },
    typography: {
      default: {
        fontFamily: 'Archivo',
        fontSize: '15px',
      },
      h1: {
        fontFamily: 'Poppins',
      },
      h2: {
        fontFamily: 'Poppins',
      },
      h3: {
        fontFamily: 'Poppins',
      },
      h6: {
        fontFamily: 'Poppins',
        fontWeight: 'medium',
        fontSize: '16px',
      },
      subtitle: {
        fontFamily: 'Poppins',
        lineHeight: '33px',
        fontSize: '25px',
        '@media (min-width:600px)': {
          fontSize: '30px',
        },
      },
      subtitle1: {
        fontFamily: 'Poppins',
        lineHeight: '33px',
        fontSize: '22px',
        '@media (min-width:600px)': {
          fontSize: '25px',
        },
      },
      subtitle2: {
        fontFamily: 'Poppins',
        lineHeight: '25px',
        fontSize: '20px',
        '@media (min-width:600px)': {
          fontSize: '22px',
        },
      },
      subtitle3: {
        fontFamily: 'Poppins',
        lineHeight: '25px',
        fontSize: '15px',
        '@media (min-width:600px)': {
          fontSize: '15px',
        },
        color: '#646464',
      },
      blueAccent: {
        fontFamily: 'Poppins',
        lineHeight: '45px',
        fontWeight: '600',
        color: '#8BAEFC',
        fontSize: '30px',
        '@media (min-width:600px)': {
          fontSize: '30px',
        },
      },
      blueAccentMed: {
        fontFamily: 'Archivo',
        lineHeight: '25px',
        fontWeight: '500',
        color: '#8BAEFC',
        fontSize: '25px',
        '@media (min-width:600px)': {
          fontSize: '25px',
        },
      },
      smallAccent: {
        fontFamily: 'Archivo',
        lineHeight: '25px',
        fontWeight: '800',
        color: '#000000',
        fontSize: '10px',
        '@media (min-width:600px)': {
          fontSize: '10px',
        },
      },
      overline: {
        fontFamily: 'Archivo',
        lineHeight: '25px',
        letterSpacing: '0',
        fontSize: '15px',
        '@media (min-width:600px)': {
          fontSize: '15px',
        },
      },
      body1: {
        fontFamily: 'Archivo',
      },
      body2: {
        fontFamily: 'Archivo',
        fontSize: '15px',
        '@media (min-width:600px)': {
          fontSize: '15px',
        },
        lineHeight: '25px',
      },
      button: {
        fontFamily: 'Archivo',
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 100,
            fontFamily: 'Archivo',
            textTransform: 'none',
            fontSize: '1rem',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            textAlign: 'right',
          },
        },
      },
    },
  });
  const [colorMode, setColorMode] = useState('light');

  // useEffect(() => {
  //   //Look for stored choice, then look for browser choice
  //   let storedTheme = localStorage.getItem('colorMode')
  //   if (storedTheme) {
  //     setColorMode(storedTheme)
  //   } else {
  //     if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  //       setColorMode('dark')
  //     } else {
  //       setColorMode('light')
  //     }
  //   }
  // },[])

  function ToggleColorMode() {
    // console.log('TOGGLING')
    if (colorMode === 'light') {
      localStorage.setItem('colorMode', 'dark');
      // document.body.appendChild(bgBodyColorStyleTag)
    } else {
      localStorage.setItem('colorMode', 'light');
      // for (let node of document.body.childNodes){
      //   if ( node.innerHTML == bgBodyColorStyleTag.innerHTML) document.body.removeChild(node)
      // }
    }
    setColorMode((prev) => (prev === 'light' ? 'dark' : 'light'));

    // theme.palette = colorScheme

    // console.log('CHANGED THEME?',theme)
  }

  let ColorModeContextProps = {
    // colorMode:colorMode,
    // theme:theme,
    ToggleColorMode: ToggleColorMode,
  };

  //handling refreshes
  // let isAuthenticated = authService.isAuthenticated();

  //useEffect(()=>{
  //  // let isAuthenticated = authService.isAuthenticated();
  //},[])
  //Running it on login to prevent inital Gandalf
  const checkAuthentication = () => {
    let isAuthenticated = authService.isAuthenticated();
    return isAuthenticated;
  };

  const getOrganization = async () => {
    let thisOrganization = await organizationService.getCurrentOrganization();
    return thisOrganization;
  };
  useEffect(() => {
    // const peakId = 'G-1MZR4T1PYG'
    const devId = 'G-QF9MEYY3L6'; // this is technically non-prod in tag manager, but it's actually prod
    let tagId;
    let hostArr = window.location.host.split('.');
    const gScript = document.createElement('script');
    if (hostArr.findIndex((el) => el == 'unionhub') > -1) {
      tagId = devId;
      gScript.id = 'gtag';
      gScript.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;
      gScript.async = true;
      document.body.appendChild(gScript);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', tagId);
    }

    return () => {
      if (document.getElementById('gtag')) document.body.removeChild(gScript);
    };
  }, []);

  const [loading, setLoading] = useState(false);

  return (
    <>
      {/* {colorMode == 'dark' &&
      <GlobalStyles
          styles={{
            body: { backgroundColor: "cyan" },
          }}
        />
      } */}

      {/* <Suspense fallback={LoadingSpinner}> */}
      <ColorModeContext.Provider value={ColorModeContextProps}>
        <ThemeProvider theme={colorMode === 'light' ? lightTheme : darkTheme}>
          <Router History>
            <Switch>
              {/* EXTERNAL MEMBER SITE  */}
              <Route
                path={['/register', '/enroll']}
                render={() => (
                  <ExternalRegister
                    getOrganization={getOrganization}
                    loading={loading}
                    setLoading={setLoading}
                  />
                )}
              />
              <Route
                path="/enrollments/:groupPlanId"
                render={() => <SingleEnrollmentWrapper />}
              />
              <Route
                path="/enrollments"
                exact={true}
                render={() => (
                  <ExternalEnrollments
                    checkAuthentication={checkAuthentication}
                  />
                )}
              />
              <Route
                path="/dashboard"
                render={() => (
                  <MemberPortal checkAuthentication={checkAuthentication} />
                )}
              />
              <Route
                path="/resetlink"
                render={() => <ExternalResetPassword />}
              />
              <Route
                path="/confirmemail"
                render={() => <ExternalConfirmEmail />}
              />
              {/* <Route path='/welcome' render={() => <ExternalMemberLanding />} /> */}
              <Route
                path="/prenote"
                render={() => (
                  <ExternalPrenoteFailure
                    checkAuthentication={checkAuthentication}
                  />
                )}
              />

              {/* RATE CALCULATOR */}
              <Route
                path="/calculator/mcl"
                render={() => <StandaloneRateCalculatorMCL />}
              />
              <Route
                path="/calculator"
                render={() => <StandaloneRateCalculator />}
              />

              {/*{TopLevelRoutes.map(route =>*/}
              {/*    <PrivateRoute key={route.path} path={route.path} exact={route.exact} component={route.component} isAuthenticated={isAuthenticated} />*/}
              {/*)}*/}

              <Route path="/devicetest" render={() => <TestPage />} />

              <Route
                path={['/login', '/']}
                render={() => (
                  <ExternalLoginAccount
                    getOrganization={getOrganization}
                    loading={loading}
                    setLoading={setLoading}
                  />
                )}
              />
              <Route path="*" render={() => <NotFound></NotFound>} />
            </Switch>
          </Router>
        </ThemeProvider>
      </ColorModeContext.Provider>
      {/* </Suspense> */}
    </>
  );
}
