import React, { useState, useEffect } from 'react';

//Assets

//Services
import enrollmentService from '../../../../services/enrollment-service';

//Mui Icons
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

//MuiComponents
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  InputAdornment,
  InputLabel,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Switch,
  Tooltip,
  TextField,
  Typography,
} from '@mui/material';

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//Our components
import ExpandAndCollapseIcon from '../enrollment-custom-components/ExpandAndCollapseIcon';

//Enrollment Beneficiary Components styles
import enrollmentBeneficiariesStyles from './enrollmentBeneficiariesStyles';
//Global styles
import externalEnrollmentSharedStyles from '../../externalEnrollmentSharedStyles';

export function ContingentBeneficiaries(props) {
  //contingent expanded logic
  const [showContingents, setShowContingents] = useState(null);
  const [contingentExpanded, setContingentExpanded] = useState(false);

  const handleContingentExpandClick = () => {
    setContingentExpanded((prev) => !prev);
  };

  useEffect(() => {
    let showContingents = false;
    if (props.contingentCheckboxes) {
      for (let contCheck of props.contingentCheckboxes) {
        if (contCheck.checked) showContingents = true;
      }
      setShowContingents(showContingents);
      setContingentExpanded(showContingents);
    }
  }, [props.contingentCheckboxes]);

  //if unsaved changes, clear form on close
  const clearContingentAmounts = () => {
    let _contingentChecks = [...props.contingentCheckboxes];
    for (let contingentCheck of _contingentChecks) {
      contingentCheck.checked = false;
      contingentCheck.amount = 0;
    }
    props.setContingentCheckboxes(_contingentChecks);
  };

  // HANDLE CONTINGENT BENEFICIARY CHECKBOX CLICK
  const handleContingentBeneficiaryClick = (e, beneficiary) => {
    let _contingentCheckboxes = props.contingentCheckboxes?.map((x) => x);

    let checkObjInState;
    if (beneficiary.id) {
      checkObjInState = _contingentCheckboxes.find(
        (checkObj) => checkObj.id == beneficiary.id
      );
    } else if (beneficiary.dependentId) {
      checkObjInState = _contingentCheckboxes.find(
        (checkObj) => checkObj.dependentId == beneficiary.dependentId
      );
    }
    checkObjInState.checked = !checkObjInState.checked;
    //  if (contingentAutoAllocate) {
    let totalChecked = 0;
    for (let checkObj of _contingentCheckboxes) {
      if (checkObj.checked) totalChecked += 1;
    }

    let evenSplitAmount = Math.floor(100 / totalChecked);
    let remainder = 100 % totalChecked;
    let i = 0;
    for (let checkObj of _contingentCheckboxes) {
      if (checkObj.checked) {
        if (remainder > 0 && i === 0)
          checkObj.amount = Number(evenSplitAmount + remainder);
        else checkObj.amount = Number(evenSplitAmount);
      } else {
        checkObj.amount = 0;
      }
      i++;
    }
    //  } else {
    //    for (let checkObj of _contingentCheckboxes) {
    //      if (!checkObj.checked) {
    //        checkObj.amount = 0;
    //      }
    //    }
    //  }

    checkContingentTotalAmount(_contingentCheckboxes);
    props.setContingentCheckboxes(_contingentCheckboxes);

    //run this to update bool value to show contingent checkboxes have been changed
    props?.manageContingentCheckboxChanges();
  };

  //MANUAL CONTINGENT ALLOCATION ENTRY
  const manualContingentAllocationHandler = (e, beneficiary, i) => {
    // setContingentAutoAllocate(false)

    let value = e.target.value;
    // console.log(e.target, beneficiary)

    let _contingentCheckboxes = props.contingentCheckboxes?.map((x) => x);

    let _enableContingentInputAutoAllocate = [
      ...enableContingentInputAutoAllocate,
    ];
    _enableContingentInputAutoAllocate[i] = false;

    let checkObjInState;
    if (beneficiary.id) {
      checkObjInState = _contingentCheckboxes.find(
        (checkObj) => checkObj.id == beneficiary.id
      );
    } else if (beneficiary.dependentId) {
      checkObjInState = _contingentCheckboxes.find(
        (checkObj) => checkObj.dependentId == beneficiary.dependentId
      );
    }
    let unhandledAmount = 100;
    //subtract each checked benny amount from unhandledAmount
    for (let j = 0; j < _contingentCheckboxes.length; j++) {
      if (
        _contingentCheckboxes[j].checked &&
        !enableContingentInputAutoAllocate[j]
      )
        unhandledAmount -= _contingentCheckboxes[j].amount;
    }

    //check that the value entered is not greater than the unhandled amount remaining
    if (value <= unhandledAmount) {
      checkObjInState.amount = Number(value);
    } else {
      console.log('amount entered is greater than unhandled amount');
    }

    let totalChecked = _contingentCheckboxes.filter(
      (check) => check.checked
    ).length;
    let totalAutoAllowed = _enableContingentInputAutoAllocate.filter(
      (x) => x == true
    ).length;
    let evenSplitAmount = Math.floor(
      Math.abs(unhandledAmount - value) / totalAutoAllowed
    );
    let remainder = (unhandledAmount - value) % totalAutoAllowed;

    //  console.log(
    //    "value",
    //    value,
    //    "\n\ntotal checked",
    //    totalChecked,
    //    "\n\ntotal auto allowed",
    //    totalAutoAllowed,
    //    "\n\neven split",
    //    evenSplitAmount,
    //    "\n\nremainder",
    //    remainder
    //  );

    let index = 0;
    for (let otherCheckedBenny of _contingentCheckboxes) {
      if (
        otherCheckedBenny.checked &&
        otherCheckedBenny != checkObjInState &&
        value <= unhandledAmount
      ) {
        // console.log('reallocating other bennies here', otherCheckedBenny, 100 - value)
        //There's only two checked, so we can adjust the other regardless of enableInputAutoAllocate[index]
        if (totalChecked == 2) otherCheckedBenny.amount = 100 - value;
        //
        else if (_enableContingentInputAutoAllocate[index]) {
          if (totalAutoAllowed == 1)
            otherCheckedBenny.amount = unhandledAmount - value;
          else otherCheckedBenny.amount = evenSplitAmount;
          if (remainder) otherCheckedBenny.amount += remainder;
          remainder = 0;
        }
      }
      index++;
    }

    checkContingentTotalAmount(_contingentCheckboxes);
    props.setContingentCheckboxes(_contingentCheckboxes);
    setEnableContingentInputAutoAllocate(_enableContingentInputAutoAllocate);
  };

  //CHECK CONTINGENT TOTAL AMOUNT
  const checkContingentTotalAmount = (contingentChecks) => {
    let total = 0;
    let checked = false;
    for (let contingentCheck of contingentChecks) {
      if (contingentCheck.checked) {
        checked = true;
        total += Number(contingentCheck.amount);
      }
    }
    if (checked && total !== 100) {
      props?.setContingentTotalError(true);
    } else props?.setContingentTotalError(false);
  };

  //enable auto allocate
  const [
    enableContingentInputAutoAllocate,
    setEnableContingentInputAutoAllocate,
  ] = useState([false, true]);

  //whenever contingent checkboxes change (whether it be length OR checked/unchecked), update enableContingentInputAutoAllocate value that corresponds to the index of the contingent checkbox
  //IF a contingent checkbox is checked, enableContingentAutoAllocate is true
  useEffect(() => {
    if (props.contingentCheckboxes && !props?.autoListBuilt) {
      let _enableContingentInputAutoAllocate = [
        ...enableContingentInputAutoAllocate,
      ];
      let i = 0;
      for (let contingentCheck of props.contingentCheckboxes) {
        //Spouse is first, and will take precedence, so no autoAllocate from other input changes
        // if (i == 0) _enableInputAutoAllocate[i] = false
        // if (i == 0) _enableInputAutoAllocate[i] = true
        // else if (primaryCheck.checked) _enableInputAutoAllocate[i] = true
        if (contingentCheck.checked)
          _enableContingentInputAutoAllocate[i] = true;
        else _enableContingentInputAutoAllocate[i] = false;
        i++;
      }
      setEnableContingentInputAutoAllocate(_enableContingentInputAutoAllocate);
      // setAutoListBuilt(true)
    }
  }, [props.contingentCheckboxes?.length, props.contingentCheckboxes]);

  const toggleContingentAutoAllocate = (i) => {
    let _enableContingentInputAutoAllocate = [
      ...enableContingentInputAutoAllocate,
    ];
    _enableContingentInputAutoAllocate[i] =
      !_enableContingentInputAutoAllocate[i];
    setEnableContingentInputAutoAllocate(_enableContingentInputAutoAllocate);
  };
  const disableContingentAutoAllocate = (i) => {
    let _enableContingentInputAutoAllocate = [
      ...enableContingentInputAutoAllocate,
    ];
    _enableContingentInputAutoAllocate[i] = false;
    setEnableContingentInputAutoAllocate(_enableContingentInputAutoAllocate);
  };

  //When selected in primary, is removed from contingent, thus reallocate
  useEffect(() => {
    let _contingentCheckboxes = [...props?.contingentCheckboxes];
    if (props?.storedAllocations.length <= 0) {
      let totalChecked = 0;
      for (let checkObj of _contingentCheckboxes) {
        if (checkObj.checked) totalChecked += 1;
      }

      let evenSplitAmount = Math.floor(100 / totalChecked);

      for (let checkObj of _contingentCheckboxes) {
        if (checkObj.checked) {
          checkObj.amount = evenSplitAmount;
        } else {
          checkObj.amount = 0;
        }
      }
    } else {
      for (let checkObj of _contingentCheckboxes) {
        if (!checkObj.checked) {
          checkObj.amount = 0;
        }
      }
    }
    props.setContingentCheckboxes(_contingentCheckboxes);

    checkContingentTotalAmount(props.contingentCheckboxes);
  }, [props.contingentBeneficiaries?.length, props.contingentBeneficiaries]);

  //keep track of added contingent benny
  const [addedContingentBenny, setAddedContingentBenny] = useState();
  const selectNewContingentBenny = (benny) => {
    let e;
    let _newBenny = props.contingentCheckboxes.find((check) => check == benny);
    if (_newBenny) handleContingentBeneficiaryClick(e, _newBenny);
  };

  useEffect(() => {
    if (addedContingentBenny) {
      let e;
      selectNewContingentBenny(addedContingentBenny);
      handleContingentBeneficiaryClick(e, addedContingentBenny);
      setAddedContingentBenny(false);
    }
  }, [props.contingentCheckboxes?.length]);

  //ADD A NEW CONTINGENT BENEFICIARY
  const handleAddContingentBeneficiary = async (e) => {
    let _contingentBeneficiaries = props.contingentBeneficiaries?.map((x) => x);
    let _contingentCheckboxes = props.contingentCheckboxes?.map((x) => x);

    let insertBenny = {
      FirstName: props.contingentBennyAdd.FirstName,
      MiddleInitial: '',
      LastName: props.contingentBennyAdd.LastName,
      RelationshipTypeId: props.contingentBennyAdd.RelationshipTypeId,
      // dob: props.contingentBennyAdd.dob || null,
      IsDeleted: false,
    };
    if (props.contingentBennyAdd.dob) {
      insertBenny.dob = props.contingentBennyAdd.dob;
    }
    let newBenny;
    if (props.agentEnroller) {
      try {
        newBenny = await enrollmentService.addBeneficiaryForMember(
          props?.fullMemberObj?.id,
          insertBenny
        );
      } catch (error) {
        console.log('error adding benny for member', error);
      }
    } else {
      try {
        newBenny = await enrollmentService.addBeneficiary(insertBenny);
      } catch (error) {
        console.log('error adding benny', error);
      }
    }

    _contingentBeneficiaries.push(newBenny);
    props.setContingentBeneficiaries(_contingentBeneficiaries);

    let newBennyAllocation = {
      id: newBenny.id,
      amount: 0,
      checked: true,
    };
    setAddedContingentBenny(newBennyAllocation);
    _contingentCheckboxes.push(newBennyAllocation);
    props.setContingentCheckboxes(_contingentCheckboxes);

    props.setContingentBennyAdd(false);
    props?.setDisableSaveContingent(true);
    // props.getInitialBeneficiaries()
  };

  return (
    <>
      <Box sx={enrollmentBeneficiariesStyles.addContingentQuestionContainer}>
        <Typography
          sx={enrollmentBeneficiariesStyles.addContingentQuestionText}
        >
          Would you like to add a Contingent Beneficiary?
        </Typography>

        <Box sx={enrollmentBeneficiariesStyles.addContingentButtonContainer}>
          <Button
            variant={showContingents === true ? 'contained' : 'outlined'}
            sx={enrollmentBeneficiariesStyles.addContingentButton}
            onClick={() => {
              setShowContingents(true);
              setContingentExpanded(true);
            }}
          >
            YES
          </Button>

          <Button
            variant={showContingents === false ? 'contained' : 'outlined'}
            sx={{
              ...enrollmentBeneficiariesStyles.addContingentButton,
              marginLeft: '12px',
            }}
            onClick={() => {
              setShowContingents(false);
              setContingentExpanded(false);
              clearContingentAmounts();
            }}
          >
            NO
          </Button>
        </Box>
      </Box>

      {/* Contingent Benny Title and Expand/Collapse Button */}
      <Box>
        <Collapse in={showContingents} unmountOnExit>
          <Box sx={enrollmentBeneficiariesStyles.sectionHeaderRow}>
            <Typography
              sx={enrollmentBeneficiariesStyles.sectionTitle}
              onClick={handleContingentExpandClick}
            >
              Contingent Beneficiary
            </Typography>

            <Box
              sx={enrollmentBeneficiariesStyles.expandAndCollapseButton}
              onClick={handleContingentExpandClick}
            >
              <ExpandAndCollapseIcon isExpanded={contingentExpanded} />
            </Box>
          </Box>
          <Divider sx={enrollmentBeneficiariesStyles.divider} />
        </Collapse>
      </Box>

      <Box>
        <Collapse
          in={contingentExpanded}
          sx={enrollmentBeneficiariesStyles.collapseSection}
          unmountOnExit
        >
          {/* CONTINGENTS */}
          <Box sx={enrollmentBeneficiariesStyles.sectionInstructionRow}>
            <Typography
              sx={enrollmentBeneficiariesStyles.sectionInstructionText}
            >
              Please select your contingent beneficiaries.
            </Typography>

            <Tooltip
              title="if adding multiple beneficiaries, allocation must total 100%"
              placement="bottom-end"
              arrow
              enterTouchDelay={100}
            >
              <Box sx={enrollmentBeneficiariesStyles.infoTipContainer}>
                <InfoOutlinedIcon
                  sx={externalEnrollmentSharedStyles.grayInfoTip}
                />
              </Box>
            </Tooltip>
          </Box>

          {/* for future use */}
          {/* <FormGroup sx={{margin: '0px 0px 10px 0px'}}>
                <FormControlLabel
                  control={
                    <Switch
                      // checked={evenSplitPrimary}
                      // onChange={() => setEvenSplitPrimary(!evenSplitPrimary)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Evenly split allocations"
                />
          </FormGroup> */}

          {contingentExpanded &&
            props.contingentBeneficiaries?.length > 0 &&
            props.contingentBeneficiaries?.map((contingentBeneficiary, i) => (
              <Box
                sx={enrollmentBeneficiariesStyles.singleBennyRow}
                key={
                  contingentBeneficiary?.id || contingentBeneficiary.dependentId
                }
              >
                <Checkbox
                  checked={
                    props.contingentCheckboxes.find((checkObj) => {
                      if (
                        checkObj.id &&
                        checkObj.id == contingentBeneficiary.id
                      ) {
                        // console.log('found cont id', checkObj)
                        return checkObj;
                      } else if (
                        checkObj.dependentId &&
                        checkObj.dependentId ==
                          contingentBeneficiary.dependentId
                      ) {
                        // console.log('ELSE found', checkObj)
                        return checkObj;
                      } else return null;
                    })?.checked || false
                  }
                  onClick={(e) => {
                    handleContingentBeneficiaryClick(e, contingentBeneficiary);
                    toggleContingentAutoAllocate(i);
                  }}
                  sx={enrollmentBeneficiariesStyles.checkboxStyle}
                  icon={
                    <CheckBoxOutlineBlankIcon
                      sx={enrollmentBeneficiariesStyles.uncheckedIcon}
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon
                      sx={enrollmentBeneficiariesStyles.checkedIcon}
                    />
                  }
                />

                {/* Name and relationship column */}
                <Box
                  sx={
                    enrollmentBeneficiariesStyles.nameAndRelationshipContainer
                  }
                >
                  <Box sx={enrollmentBeneficiariesStyles.nameText}>
                    {contingentBeneficiary.firstName}{' '}
                    {contingentBeneficiary.lastName}
                  </Box>

                  <Box sx={enrollmentBeneficiariesStyles.relationshipText}>
                    {props?.relationshipTypes &&
                      props?.relationshipTypes.find(
                        (relationshipType) =>
                          relationshipType.id ==
                          contingentBeneficiary.relationshipTypeId
                      )?.name}
                  </Box>
                </Box>

                <Box>
                  <TextField
                    fullWidth
                    label="Allocation"
                    error={props?.contingentTotalError}
                    type="number"
                    autoComplete="off"
                    InputProps={{
                      ...enrollmentBeneficiariesStyles.textFieldInputProps,
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: '100',
                      min: '0',
                      type: 'number',
                      'data-allocate': enableContingentInputAutoAllocate[i],
                    }}
                    onFocus={(e) => e.target.select()}
                    onBlur={(e) => disableContingentAutoAllocate(i)}
                    value={
                      Number(
                        props.contingentCheckboxes.find((checkObj) => {
                          if (checkObj.id)
                            return checkObj.id == contingentBeneficiary.id;
                          else if (checkObj.dependentId)
                            return (
                              checkObj.dependentId ==
                              contingentBeneficiary.dependentId
                            );
                        })?.amount
                      ) || ''
                    }
                    onChange={(e) =>
                      manualContingentAllocationHandler(
                        e,
                        contingentBeneficiary,
                        i
                      )
                    }
                    //disable textfield if checkbox is not checked
                    disabled={
                      !props.contingentCheckboxes.find((checkObj) => {
                        if (
                          checkObj.id &&
                          checkObj.id === contingentBeneficiary.id
                        )
                          return checkObj;
                        else if (
                          checkObj.dependentId &&
                          checkObj.dependentId ===
                            contingentBeneficiary.dependentId
                        )
                          return checkObj;
                        else return undefined;
                      })?.checked
                    }
                  />
                </Box>
              </Box>

              //NEED to add edit existing contingent benny logic once design give ok to start this functionality
            ))}

          <Box sx={enrollmentBeneficiariesStyles.addAdditionalBennyCheckRow}>
            <Checkbox
              checked={props.contingentBennyAdd}
              onClick={() => props.setContingentBennyAdd((state) => !state)}
              sx={enrollmentBeneficiariesStyles.checkboxStyle}
              icon={
                <CheckBoxOutlineBlankIcon
                  sx={enrollmentBeneficiariesStyles.uncheckedIcon}
                />
              }
              checkedIcon={
                <CheckBoxIcon sx={enrollmentBeneficiariesStyles.checkedIcon} />
              }
            />

            <Typography
              sx={enrollmentBeneficiariesStyles.addAdditionalBennyText}
            >
              Add a contingent beneficiary
            </Typography>
          </Box>

          {/* ADD A NEW contingent form */}
          {props.contingentBennyAdd && (
            <>
              <Box sx={enrollmentBeneficiariesStyles.inputField}>
                <TextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  value={props.contingentBennyAdd.FirstName || ''}
                  onChange={props?.handleInputChange}
                  inputProps={{
                    'data-update': 'firstNameContingent',
                  }}
                />
              </Box>

              <Box sx={enrollmentBeneficiariesStyles.inputField}>
                <TextField
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  value={props.contingentBennyAdd.LastName || ''}
                  onChange={props?.handleInputChange}
                  inputProps={{
                    'data-update': 'lastNameContingent',
                  }}
                />
              </Box>

              <Box sx={enrollmentBeneficiariesStyles.inputField}>
                <TextField
                  fullWidth
                  select
                  // sx={{
                  //   '& fieldset': { borderRadius: '8px' },
                  //   //   '& fieldset > legend': { display: 'none' },
                  //   '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                  //     border: '2px solid #195ffb',
                  //   },
                  // }}
                  variant="outlined"
                  label="Relationship"
                  value={props.contingentBennyAdd.RelationshipTypeId || ''}
                  onChange={(e) =>
                    props.setContingentBennyAdd({
                      ...props.contingentBennyAdd,
                      RelationshipTypeId: e.target.value,
                    })
                  }
                >
                  {props?.relationshipTypes &&
                    props?.relationshipTypes?.map((relationshipType) => (
                      <MenuItem
                        key={relationshipType.id}
                        value={relationshipType.id}
                      >
                        {relationshipType.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>

              <Box sx={enrollmentBeneficiariesStyles.inputField}>
                {/* Contingent Dob */}
                <Box>
                  <LocalizationProvider
                    required={false}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      required={false}
                      // onBlur={(e) => checkError("dob")}
                      disableFuture
                      label="Date of Birth"
                      openTo="year"
                      views={['year', 'month', 'day']}
                      value={props.contingentBennyAdd.dob || null}
                      onChange={(e) => props?.handleMobileDobChange(e, false)}
                      format="MM/dd/yyyy"
                      sx={{ width: '100%' }}
                      slotProps={{
                        openPickerButton: { sx: { visibility: 'hidden' } }, //hide calendar icon calandar
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>

              <Box sx={enrollmentBeneficiariesStyles.buttonContainer}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={props?.disableSaveContingent}
                  sx={enrollmentBeneficiariesStyles.saveButton}
                  onClick={handleAddContingentBeneficiary}
                >
                  Save
                </Button>
              </Box>
            </>
          )}
        </Collapse>
      </Box>

      {!contingentExpanded && (
        <>
          {props.contingentBeneficiaries &&
            props.contingentBeneficiaries?.map((contingentBeneficiary, i) => {
              let contingentCheck = props.contingentCheckboxes.find(
                (contingentCheck) =>
                  contingentCheck.id == contingentBeneficiary.id
              );
              if (contingentCheck?.checked) {
                return (
                  <Box sx={enrollmentBeneficiariesStyles.collapsedRow}>
                    <Typography
                      sx={enrollmentBeneficiariesStyles.collapsedRowText}
                    >
                      {contingentBeneficiary.firstName}{' '}
                      {contingentBeneficiary.lastName}
                    </Typography>

                    <Typography
                      sx={enrollmentBeneficiariesStyles.collapsedRowText}
                    >
                      {contingentCheck.amount}%
                    </Typography>
                  </Box>
                );
              }
            })}
        </>
      )}
    </>
  );
}
