import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

//MuiIcons
import {
  KeyboardBackspaceOutlined,
  MoreVertOutlined,
} from '@mui/icons-material';

//MuiComponents
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Button,
  Paper,
  Typography,
  Tabs,
  Tab,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import Fade from '@mui/material/Fade';

//Assets

//Our components
import { thisUserContext } from '../nav-container/NavContainer';
import PlanManagementDrawer from './planManagementDrawer';
import planBuildService from '../../../services/planBuild-service';

export default function SinglePlanManagement(props) {
  let history = useHistory();

  let myContext = useContext(thisUserContext);
  const [thisUser, setThisUser] = useState();
  const [permissions, setPermissions] = useState();

  let getThisUser = async () => {
    setThisUser(myContext.thisUser);
    setPermissions(myContext?.permissionsEnabler);
  };

  useEffect(() => {
    if (myContext?.thisUser && thisUser == null) getThisUser();
  }, [myContext?.thisUser]);

  const [groupPlan, setGroupPlan] = useState({
    groupPlan: {},
    plans: [],
  });
  //   groupPlan: {
  //     id: 9,
  //     name: 'BLET Life & Disability',
  //     description: 'An awesome plan for the group',
  //     subDomain: 'blet',
  //     enrollmentWindowId: 10,
  //     openEnrollmentStart: '2023-02-24T00:00:00-07:00',
  //     openEnrollmentEnd: '2024-09-28T04:00:00Z',
  //     paymentStart: '2024-09-01T00:00:00Z',
  //   },
  //   plans: [
  //     {
  //       plan: {
  //         id: 25,
  //         typeId: 4,
  //         title: 'BLET',
  //         sortOrder: 1,
  //         carrierName: 'Prudential',
  //         policyNumber: '70643',
  //         helpText:
  //           '<ul>\r\n<li>Pays a flat weekly benefit of either $200, $400, or $600.</li>\r\n<li>Benefit pays for up to 48 weeks.</li>\r\n<li>Pays on day 30 for injury or illness.</li>\r\n<li>Pre-existing conditions are covered after 12 months.</li>\r\n<li>Coverage is not offset (reduced) by other benefits until 80% of pre-disability earnings are attained.</li>\r\n<li>Benefits paid are tax free.</li>\r\n<li>Covers on and off the job disabilities resulting from injury or illness.</li>\r\n</ul>',
  //         infoTip:
  //           'RRB: You may or may not be eligible for Railroad Retirement Board (RRB) sickness or disability benefits after 26 weeks. RRB benefit amount is for illustrative purposes only; actual amounts may\r\nvary. More information is available by calling an RRB office toll-free at (877) 772-5772.',
  //       },
  //     },
  //     {
  //       plan: {
  //         id: 26,
  //         typeId: 3,
  //         title: 'BLET',
  //         sortOrder: 0,
  //         carrierName: 'Prudential',
  //         policyNumber: '70643',
  //         helpText:
  //           '<ul>\r\n<li>Pays after 365 day waiting period (starts when STD ends).</li>\r\n<li>Pre-existing conditions are covered after 24 months.</li>\r\n<li>Covers on and off the job disabilities resulting from injury or illness.</li>\r\n<li>Benefits paid are tax free.</li>\r\n</ul>',
  //         infoTip:
  //           'Annual earnings include your total compensation for the year including overtime. Call (224) 770-5307 to get premiums for annual earnings amounts not listed above. Benefits are subject to offsets.',
  //       },
  //     },
  //     {
  //       plan: {
  //         id: 27,
  //         typeId: 5,
  //         title: 'BLET',
  //         sortOrder: 2,
  //         carrierName: 'Prudential',
  //         policyNumber: '70643',
  //         helpText:
  //           '<ul>\r\n<li>Members currently not enrolled can elect coverage up to $200,000 in $10,000 increments with no medical questions asked.</li>\r\n<li>Coverage includes an equal amount of Accidental Death and Dismemberment.</li>\r\n<li>Life coverage is convertible and portable.</li>\r\n<li>Spouse and child Life coverage available when Member Life is elected (up to 50% of Member election).</li>\r\n</ul>',
  //         infoTip: '',
  //       },
  //     },
  //   ],
  // });
  const getGroupPlan = async () => {
    console.log('getting group plan...propssssss', props);
    const results = await planBuildService.getGroupPlanById(
      props.match.params.id
    );
    if (results) {
      let builtGroupPlan = {
        ...results,
        plans: JSON.parse(results.plans),
        enrollmentWindows: JSON.parse(results.enrollmentWindows),
        // .map((plan) => {
        //   return {
        //     plan: {
        //       ...plan,
        //       helpText: plan.helpText.replace(/(?:\r\n|\r|\n)/g, '<br>'),
        //     },
        //   };
        // }),
      };
      console.log(builtGroupPlan);
      setGroupPlan(builtGroupPlan);
    }
  };
  useEffect(() => {
    getGroupPlan();
  }, []);

  const [selectedGroupPlan, setSelectedGroupPlan] = useState(
    props.match?.params?.id
  );

  const handleChange = (event) => {
    setSelectedGroupPlan(event.target.value);
    console.log(event.target.value);
    // console.log(groupPlans.filter(groupPlan => groupPlanId == selectedGroupPlan))
  };

  const handleBack = (event) => {
    history.push(`/planmanagement/`);
  };

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    console.log(newValue);
    setTabValue(newValue);
  };

  const [editHelpText, setEditHelpText] = useState(false);

  const buildRateTable = (rate) => {
    return (
      <>
        <style>{`
    table{
      border-radius: 8px;
     border:1px solid rgba(224, 224, 224, 1),;
    }
    th{
      text-align:left;
      border:1px solid rgba(224, 224, 224, 1);
      padding:10px;
    }
    tr{
      text-align:left;
      border:1px solid rgba(224, 224, 224, 1);
      padding:10px;
    }
    td {
      text-align:left;
      border:1px solid rgba(224, 224, 224, 1);
      padding: 5px;
    }
  `}</style>
        <Box sx={{ display: 'block' }}>
          <Typography variant="body1">{rate.title}</Typography>
          <table>
            <tr>
              <th>Age Band</th>
              <th>Rate</th>
            </tr>
            {rate.rateOptions.map((rateOption) => (
              <tr>
                <td>{`${rateOption.begin} - ${rateOption.end}`}</td>
                <td>{rateOption.rate}</td>
              </tr>
            ))}
            <tr></tr>
          </table>
        </Box>
      </>
    );
  };

  const [activeTab, setActiveTab] = useState(0);

  const planTypeLabels = [
    {
      label: 'Accident',
      Id: 1,
    },
    {
      label: 'Critical Illness',
      Id: 2,
    },
    {
      label: 'Long Term Disability',
      Id: 3,
    },
    {
      label: 'Short Term Disability',
      Id: 4,
    },
    {
      label: 'Life and AD&D',
      Id: 5,
    },
    {
      label: 'Hospital',
      Id: 6,
    },
    {
      label: 'Cancer',
      Id: 7,
    },
    {
      label: 'AD&D',
      Id: 8,
    },
  ];

  const buildTabs = () => {
    return (
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="group plan navigator"
        sx={{ marginTop: '20px' }}
      >
        <Tab value={0} label="General" />
        {groupPlan.plans.map((plan, index) => (
          <Tab
            value={index + 1}
            label={
              planTypeLabels.filter(
                (planType) => planType.Id == plan.plan.typeId
              )[0].label
            }
            id={plan.plan.id}
          />
        ))}
      </Tabs>
    );
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editValue, setEditValue] = useState('');
  const [updateObject, setUpdateObject] = useState({});

  const handleEdit = (value, entity, id, attribute) => {
    setEditValue(value);
    setDrawerOpen(true);
    setUpdateObject({
      type: entity,
      id: id,
      valueType: attribute,
    });
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          borderColor: 'background.border',
        }}
        style={{
          height: 'calc(100vh - 56px)',
          padding: '20px 40px',
          overflowX: 'auto',
        }}
      >
        <Button
          variant="text"
          sx={{
            marginBottom: '20px',
            fontSize: '.8rem',
            textTransform: 'uppercase',
          }}
          onClick={handleBack}
        >
          <KeyboardBackspaceOutlined />
          Back to all group plans
        </Button>
        <Typography variant="h4">{groupPlan.name}</Typography>
        <Box
          className=""
          sx={{ justifyContent: 'space-between', marginBottom: '10px' }}
        >
          {buildTabs()}

          {/* General Tab */}
          {tabValue == 0 && (
            <>
              <Table
                sx={{
                  minWidth: 650,
                  border: '1px solid #D6D6D6',
                  marginTop: '40px',
                  borderRadius: '4px',
                }}
              >
                <TableBody>
                  <TableRow hover>
                    <TableCell variant="head" width="200px">
                      URL
                    </TableCell>
                    <TableCell align="left">{`https://${groupPlan.subdomain}.unionhub.com/enroll`}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell variant="head">Group Plan ID</TableCell>
                    <TableCell align="left">
                      {props.match?.params?.id}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell variant="head">Group Plan Description</TableCell>
                    <TableCell align="left">
                      <Box
                        sx={{ maxHeight: '200px', overflow: 'auto' }}
                        dangerouslySetInnerHTML={{
                          __html: groupPlan.description,
                        }}
                      ></Box>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() =>
                          handleEdit(
                            groupPlan.description,
                            'groupplan',
                            groupPlan.id,
                            'description'
                          )
                        }
                      >
                        <MoreVertOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell variant="head">Terms and Conditions</TableCell>
                    <TableCell align="left">
                      <Box
                        sx={{ maxHeight: '200px', overflow: 'auto' }}
                        dangerouslySetInnerHTML={{
                          __html: groupPlan.termsAndConditions,
                        }}
                      ></Box>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell variant="head">ACH Terms</TableCell>
                    <TableCell align="left">
                      <Box
                        sx={{ maxHeight: '200px', overflow: 'auto' }}
                        dangerouslySetInnerHTML={{
                          __html: groupPlan.achTerms,
                        }}
                      ></Box>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell variant="head">Verbal Signature</TableCell>
                    <TableCell align="left">
                      <Box
                        sx={{ maxHeight: '200px', overflow: 'auto' }}
                        dangerouslySetInnerHTML={{
                          __html: groupPlan.verbalSignature,
                        }}
                      ></Box>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell variant="head">Enrollment Windows</TableCell>
                    <TableCell align="left">
                      {groupPlan?.enrollmentWindows?.map((enrollmentWindow) => {
                        return (
                          <Typography variant="body1">
                            {`${new Date(
                              enrollmentWindow?.OpenEnrollmentStart
                            ).toDateString()} - ${new Date(
                              enrollmentWindow?.OpenEnrollmentEnd
                            ).toDateString()}`}
                          </Typography>
                        );
                      })}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
          )}

          {/* Plan Tabs */}
          {tabValue > 0 && (
            <>
              <Table
                sx={{
                  minWidth: 650,
                  border: '1px solid #D6D6D6',
                  marginTop: '40px',
                  borderRadius: '4px',
                }}
              >
                <TableBody>
                  <TableRow hover>
                    <TableCell variant="head" width="200px">
                      Plan ID
                    </TableCell>
                    <TableCell align="left">
                      {groupPlan.plans[tabValue - 1].plan.id}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell variant="head">Insurance Carrier</TableCell>
                    <TableCell align="left">
                      {groupPlan.plans[tabValue - 1].plan.carrierName}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell variant="head">Policy Number</TableCell>
                    <TableCell align="left">
                      {groupPlan.plans[tabValue - 1].plan.policyNumber}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell variant="head">Plan Description</TableCell>
                    <TableCell align="left">
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: groupPlan.plans[tabValue - 1].plan.helpText,
                        }}
                      ></Box>{' '}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() =>
                          handleEdit(
                            groupPlan.plans[tabValue - 1].plan.helpText,
                            'plan',
                            groupPlan.plans[tabValue - 1].plan.id,
                            'helptext'
                          )
                        }
                      >
                        <MoreVertOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
          )}
        </Box>

        <PlanManagementDrawer
          groupPlan={groupPlan}
          setGroupPlan={setGroupPlan}
          update={planBuildService.update}
          updateObject={updateObject}
          tabValue={tabValue}
          setTabValue={setTabValue}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          editValue={editValue}
          setEditValue={setEditValue}
        />
      </Box>
    </>
  );
}
