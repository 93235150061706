import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

// Assets

//Mui Components

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  FormControl,
  InputLabel,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import MUIRichTextEditor from 'mui-rte';
import TMUIRichTextEditorRef from 'mui-rte';
import {
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  createWithContent,
  ContentState,
  createFromBlockArray,
  EditorState,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

//Mui icons
import CloseIcon from '@mui/icons-material/Close';

//Our Components
import planBuildService from '../../../services/planBuild-service';

export default function PlanManagementDrawer(props) {
  //Drawer Logic
  const handleDrawerClose = (e) => {
    props.setDrawerOpen(false);
  };

  const convertToRTE = (value) => {
    if (!value) value = '<p></p>';
    let contentHTML = convertFromHTML(value);
    console.log('this will be turned into a content state', contentHTML);
    const state = ContentState.createFromBlockArray(
      contentHTML.contentBlocks,
      contentHTML.entityMap
    );
    const content = JSON.stringify(convertToRaw(state));
    console.log('this is what we consider raw', content);
    return content;
  };

  const save = async (data) => {
    // const state = ContentState.createFromBlockArray(data.blocks, data.entityMap)
    props.setEditValue(stateToHTML(convertFromRaw(JSON.parse(data))));
    props.setDrawerOpen(false);
    try {
      let newUpdate = await planBuildService.update(
        props.updateObject.type,
        props.updateObject.id,
        props.updateObject.valueType,
        stateToHTML(convertFromRaw(JSON.parse(data)))
      );
      var modifiedGroupPlan = { ...props.groupPlan };
      if (props.updateObject.type === 'groupplan') {
        modifiedGroupPlan.description = stateToHTML(
          convertFromRaw(JSON.parse(data))
        );
      }
      if (props.updateObject.type === 'plan') {
        modifiedGroupPlan.plans[props.tabValue - 1].plan.helpText = stateToHTML(
          convertFromRaw(JSON.parse(data))
        );
      }
      props.setGroupPlan(modifiedGroupPlan);
    } catch (error) {
      console.log('error\n\n\n', error);
    }
    // const html = stateToHTML(state.getCurrentContent())
    // setLtdValue(html)
    // setEditLtd(false)
  };

  const change = (state) => {
    // More info about EditorState object at
    // https://draftjs.org/docs/api-reference-editor-state
    //
    // Get current selection
    // console.log(state.getSelection())

    // Get current content
    // let currentContent = JSON.stringify(convertToRaw(state.getCurrentContent()))
    // if (currentContent !== ltdValue){
    //       setLtdValue(JSON.stringify(convertToRaw(state.getCurrentContent())))

    // }

    // Get current text
    // console.log(state.getCurrentContent().getPlainText())

    // Convert to HTML
    // console.log(stateToHTML(state.getCurrentContent()))
    // setLtdValue(stateToHTML(state.getCurrentContent()))

    // Update state to match the new change
    // setLtdValue(stateToHTML(state.getCurrentContent()))

    // Check if editor is empty
    console.log('state', state);
    if (!state.getCurrentContent().hasText()) {
      console.log('empty');
    }
  };

  const ref = useRef(TMUIRichTextEditorRef);

  const handleSave = () => {
    ref.current?.save();
  };

  return (
    <>
      <Drawer
        PaperProps={{
          sx: { marginTop: '56px!important' },
        }}
        hideBackdrop={true}
        anchor="right"
        open={props.drawerOpen}
        onClose={handleDrawerClose}
      >
        <div style={{ width: '560px', padding: '19px 38px' }}>
          <div className="flex-row">
            <div className="col-6">
              <Typography variant="body1" fontFamily="Poppins" fontWeight="600">
                Modify Group Plan
              </Typography>
            </div>

            <div
              className="col-6"
              style={{ display: 'flex', justifyContent: 'flex-end' }}
              role="button"
              onClick={handleDrawerClose}
            >
              <CloseIcon />
            </div>
          </div>
          <Divider
            sx={{ color: 'primary.main' }}
            style={{ margin: '17px -38px 30px', opacity: 1, height: '2px' }}
          />

          <MUIRichTextEditor
            ref={ref}
            defaultValue={convertToRTE(props.editValue)}
            onChange={change}
            onSave={save}
            controls={[
              'bold',
              'italic',
              'underline',
              'link',
              'bulletList',
              'numberList',
              'undo',
              'redo',
            ]}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={handleDrawerClose} variant="text">
              Cancel
            </Button>
            <Button onClick={handleSave} variant="contained">
              Save
            </Button>
          </Box>
        </div>
      </Drawer>
    </>
  );
}
