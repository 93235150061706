const externalEnrollmentSharedStyles = {
  //render container styles based on agent vs. external enrollment
  componentContainer: (isAgentEnroller) => ({
    display: 'flex',
    flexDirection: 'column',
    bgcolor: 'background.default',
    color: 'text.primary',
    padding: isAgentEnroller ? '30px 20px' : '50px 35px 30px',
    height: isAgentEnroller ? 'calc(100vh - 276px)' : '100vh',
    margin: isAgentEnroller ? '' : 'auto',
    width: isAgentEnroller ? '100%' : 'auto',
    maxWidth: isAgentEnroller ? '100%' : '575px',
    justifyContent: 'space-between',
  }),
  //render enrollment container styles based on agentEnroll AND sticky progress
  //EnrollmentPlans, EnrollmentBeneficiaries, EnrollmentPersonalInfo, EnrollmentUnionInfo, EnrollmentSummary, EnrollmentPayment
  componentContainerWithPossibleStickyProgress: (isAgentEnroller) => ({
    display: 'flex',
    flexDirection: 'column',
    bgcolor: 'background.default',
    color: 'text.primary',
    padding: isAgentEnroller ? '30px 20px' : '50px 35px 30px',
    height: isAgentEnroller ? 'calc(100vh - 276px)' : 'calc(100vh - 160px)',
    margin: isAgentEnroller ? '' : '0px auto',
    width: isAgentEnroller ? '100%' : 'auto',
    maxWidth: isAgentEnroller ? '100%' : '575px',
    justifyContent: 'flex-start',
    overflowY: isAgentEnroller ? 'auto' : 'none',
    // border: isAgentEnroller ? '1px solid yellow' : '1px solid green',
  }),
  largeBlueButton: {
    height: '50px',
    fontSize: '15px',
    width: '100%',
    maxWidth: '575px',
    borderRadius: '100px',
  },
  divider: {
    color: '#989898',
    fontWeight: 700,
    fontFamily: 'Poppins',
    maxWidth: '600px',
    margin: '15px auto 25px',
  },
  externalPreEnrollmentParentContainer: {
    display: 'block',
    height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
  },
  infinityLoader: {
    position: 'absolute',
    zIndex: 5,
    width: '100%',
    height: '100vh',
    backgroundColor: '#00000050',
    backdropFilter: 'blur(10px)',
  },
  //info tip - bliue
  toolTipIcon: {
    // height: "20px",
    // color: "#717171",
    color: 'primary.main',
    height: '24px',
    width: '24px',
    marginLeft: '5px',
  },
  //info tip - gray (not-now tool tip)
  grayInfoTip: {
    color: 'primary.grayInfoTip',
    height: '24px',
    width: '24px',
    marginLeft: '5px',
  },
  iconButtonWrapper: {
    padding: '0px',
    '&:hover': {
      backgroundColor: 'transparent', // Removes the background color change on hover
    },
  },
  //AGENT ENROLLMENT HEADER
  agentEnrollTitleText: {
    fontFamily: 'Poppins',
  },
  agentEnrollSubtitleContainer: {
    margin: '10px 0px 20px',
  },
  agentEnrollSubtitleText: {
    color: 'text.gray',
  },
};
export default externalEnrollmentSharedStyles;
