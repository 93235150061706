import React, { useState, useEffect } from 'react';

//Assets

//Services
import enrollmentService from '../../../../services/enrollment-service';

//Mui Icons
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

//MuiComponents
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  InputAdornment,
  InputLabel,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Switch,
  Tooltip,
  TextField,
  Typography,
} from '@mui/material';

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//Our components
import ExpandAndCollapseIcon from '../enrollment-custom-components/ExpandAndCollapseIcon';

//Enrollment Beneficiary Components styles
import enrollmentBeneficiariesStyles from './enrollmentBeneficiariesStyles';
//Global styles
import externalEnrollmentSharedStyles from '../../externalEnrollmentSharedStyles';

export default function PrimaryBeneficiaries(props) {
  //expand section view logic
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  //whenever primary checkboxes change (whether it be length OR checked/unchecked), update enableInputAutoAllocate value that corresponds to the index of the primary checkbox
  //IF a primary checkbox is checked, enableInputAutoAllocate is true
  // HANDLE BENEFICIARY CHECKBOX CLICK
  const handleBeneficiaryClick = (e, beneficiary) => {
    let _primaryCheckboxes = [...props.primaryCheckboxes];
    let checkObjInState;
    if (beneficiary.id) {
      checkObjInState = _primaryCheckboxes.find(
        (checkObj) => checkObj.id == beneficiary.id
      );
    } else if (beneficiary.dependentId) {
      checkObjInState = _primaryCheckboxes.find(
        (checkObj) => checkObj.dependentId == beneficiary.dependentId
      );
    }
    checkObjInState.checked = !checkObjInState.checked;

    //autoAllocate is set to false when a user manually enteres an allocation amount but never set back to true after that - so no matter how many checkboxes are checked, the autoAllocate logic below this will never run
    //leaving the if(autoAllocate) logic commented out for now and each time there is an error in the manual entry of allocation, checking/unchecking a box resets the allocations divided evenly between checked bennies

    // if (autoAllocate) {
    let totalChecked = 0;
    for (let checkObj of _primaryCheckboxes) {
      if (checkObj.checked) totalChecked += 1;
    }

    let evenSplitAmount = Math.floor(100 / totalChecked);
    let remainder = 100 % totalChecked;

    let i = 0;
    for (let checkObj of _primaryCheckboxes) {
      if (checkObj.checked) {
        if (remainder > 0 && i === 0)
          checkObj.amount = Number(evenSplitAmount + remainder);
        else checkObj.amount = Number(evenSplitAmount);
      } else {
        checkObj.amount = 0;
      }
      i++;
    }
    // } else {
    //    //if a user is manually changing the allocation amount, set all unchecked beneficiaries to allocation amount = 0
    //    for (let checkObj of _primaryCheckboxes) {
    //       if (!checkObj.checked) {
    //          checkObj.amount = 0
    //       }
    //    }
    // }

    checkTotalAmount(_primaryCheckboxes);
    props.setPrimaryCheckboxes(_primaryCheckboxes);
  };

  const [enableInputAutoAllocate, setEnableInputAutoAllocate] = useState([
    false,
    true,
  ]);

  useEffect(() => {
    if (props.primaryCheckboxes && !props?.autoListBuilt) {
      let _enableInputAutoAllocate = [...enableInputAutoAllocate];
      // let i=0

      for (let i = 0; i < props.primaryCheckboxes?.length; i++) {
        //Spouse is first, and will take precedence, so no autoAllocate from other input changes
        // if (i == 0) _enableInputAutoAllocate[i] = false
        //check first in list of primary checkboxes to auto allocate
        if (i == 0) _enableInputAutoAllocate[i] = true;
        //all primary checkboxes that are checked will auto allocate
        else if (props.primaryCheckboxes[i].checked)
          _enableInputAutoAllocate[i] = true;
        // all other primary checkboxes that are not checked will not auto allocate
        else _enableInputAutoAllocate[i] = false;
      }

      setEnableInputAutoAllocate(_enableInputAutoAllocate);
    }
  }, [props.primaryCheckboxes.length, props.primaryCheckboxes]);

  const toggleAutoAllocate = (i) => {
    let _enableInputAutoAllocate = [...enableInputAutoAllocate];
    _enableInputAutoAllocate[i] = !_enableInputAutoAllocate[i];
    setEnableInputAutoAllocate(_enableInputAutoAllocate);
  };
  const disableAutoAllocate = (i) => {
    let _enableInputAutoAllocate = [...enableInputAutoAllocate];
    _enableInputAutoAllocate[i] = false;
    setEnableInputAutoAllocate(_enableInputAutoAllocate);
  };

  //Check allocation totals and display error if not 100%

  const checkTotalAmount = (primaryChecks) => {
    let total = 0;
    for (let primaryCheck of primaryChecks) {
      if (primaryCheck.checked) {
        total += Number(primaryCheck.amount);
      }
    }

    if (total != 100) {
      props?.setPrimaryTotalError(true);
    } else props?.setPrimaryTotalError(false);
  };

  //MANUAL ALLOCATION ENTRY
  const manualAllocationHandler = (e, beneficiary, i) => {
    //  setAutoAllocate(false);

    let value = e.target.value;

    //  //if value is greater than 100, return error
    //    if (Number(value) > 100) {
    //       setPrimaryTotalError(true)
    //     }

    let _primaryCheckboxes = props.primaryCheckboxes?.map((x) => x);

    //copy of array [false, true]
    let _enableInputAutoAllocate = [...enableInputAutoAllocate];
    //setting enableInputAutoAllocate of whatever benny allocation amount changed to be false
    _enableInputAutoAllocate[i] = false;

    let checkObjInState;
    if (beneficiary.id) {
      checkObjInState = _primaryCheckboxes.find(
        (checkObj) => checkObj.id == beneficiary.id
      );
    } else if (beneficiary.dependentId) {
      checkObjInState = _primaryCheckboxes.find(
        (checkObj) => checkObj.dependentId == beneficiary.dependentId
      );
    }
    let unhandledAmount = 100;
    //subtract each checked benny amount from unhandledAmount
    for (let j = 0; j < _primaryCheckboxes.length; j++) {
      if (_primaryCheckboxes[j].checked && !enableInputAutoAllocate[j])
        unhandledAmount -= _primaryCheckboxes[j].amount;
    }

    //check that the value entered is not greater than the unhandled amount remaining
    if (value <= unhandledAmount) {
      checkObjInState.amount = Number(value);
    } else {
      console.log('amount entered is greater than unhandled amount');
    }

    // filter out only checked bennies and get the length
    let totalChecked = _primaryCheckboxes.filter(
      (check) => check.checked
    ).length;

    let totalAutoAllowed = _enableInputAutoAllocate.filter(
      (x) => x == true
    ).length;
    let evenSplitAmount = Math.floor(
      Math.abs(unhandledAmount - value) / totalAutoAllowed
    );
    let remainder = (unhandledAmount - value) % totalAutoAllowed;

    let index = 0;
    for (let otherCheckedBenny of _primaryCheckboxes) {
      if (
        otherCheckedBenny.checked &&
        otherCheckedBenny != checkObjInState &&
        value <= unhandledAmount
      ) {
        //There's only two checked, so we can adjust the other regardless of enableInputAutoAllocate[index]
        if (totalChecked == 2) otherCheckedBenny.amount = 100 - value;
        //
        else if (_enableInputAutoAllocate[index]) {
          if (totalAutoAllowed == 1)
            otherCheckedBenny.amount = unhandledAmount - value;
          else otherCheckedBenny.amount = evenSplitAmount;
          if (remainder) otherCheckedBenny.amount += remainder;
          remainder = 0;
        }
      }
      index++;
    }

    checkTotalAmount(_primaryCheckboxes);
    props.setPrimaryCheckboxes(_primaryCheckboxes);
    //update enableInputAutoAllocate array
    setEnableInputAutoAllocate(_enableInputAutoAllocate);
  };

  const selectNewBenny = (benny) => {
    let e;
    let _newBenny = props.primaryCheckboxes.find((check) => check == benny);
    if (_newBenny) handleBeneficiaryClick(e, _newBenny);
  };
  //Adding a benny should auto select it
  useEffect(() => {
    if (props?.addedBenny) {
      let e;
      selectNewBenny(props?.addedBenny);
      handleBeneficiaryClick(e, props?.addedBenny);
      props?.setAddedBenny(false);
    }
  }, [props.primaryCheckboxes?.length]);

  //ADD NEW PRIMARY BENNY
  const handleAddPrimaryBeneficiary = async (e) => {
    props?.setAddedBenny(true);
    let _primaryBeneficiaries = props.primaryBeneficiaries?.map((x) => x);
    let _primaryCheckboxes = props.primaryCheckboxes?.map((x) => x);

    let insertBenny = {
      FirstName: props.primaryBennyAdd.FirstName,
      MiddleInitial: '',
      LastName: props.primaryBennyAdd.LastName,
      RelationshipTypeId: props.primaryBennyAdd.RelationshipTypeId,
      // dob: props.primaryBennyAdd.dob || null,
      IsDeleted: false,
    };
    if (props.primaryBennyAdd.dob) {
      insertBenny.dob = props.primaryBennyAdd.dob;
    }
    let newBenny;
    if (props.agentEnroller) {
      try {
        newBenny = await enrollmentService.addBeneficiaryForMember(
          props?.fullMemberObj?.id,
          insertBenny
        );
      } catch (error) {
        console.log('error adding benny for member', error);
      }
    } else {
      try {
        newBenny = await enrollmentService.addBeneficiary(insertBenny);
      } catch (error) {
        console.log('error adding benny', error);
      }
    }

    _primaryBeneficiaries.push(newBenny);
    props.setPrimaryBeneficiaries(_primaryBeneficiaries);

    let newBennyAllocation = {
      id: newBenny.id,
      amount: 0,
      checked: true,
    };
    props?.setAddedBenny(newBennyAllocation);
    // selectNewBenny(newBennyAllocation)
    _primaryCheckboxes.push(newBennyAllocation);
    props.setPrimaryCheckboxes(_primaryCheckboxes);
    props.setPrimaryBennyAdd(false);
    props?.setDisableSavePrimary(true);

    //If spouse is added as primary benny and spouse is not already in db, add spouse to db
    if (
      !props?.spouse?.spouseInDb &&
      props.primaryBennyAdd.RelationshipTypeId === 1
    ) {
      //set spouse info here and save on next click in agent enrollment wrapper and single enrollment wrapper separately
      props?.setSpouse({
        ...props.spouse,
        firstName: props.primaryBennyAdd.FirstName,
        lastName: props.primaryBennyAdd.LastName,
        dateOfBirth: props.primaryBennyAdd.dob,
      });
    }
  };
  return (
    <>
      <Box sx={enrollmentBeneficiariesStyles.sectionHeaderRow}>
        <Typography
          sx={enrollmentBeneficiariesStyles.sectionTitle}
          onClick={handleExpandClick}
        >
          Primary Beneficiary
        </Typography>

        <Box
          sx={enrollmentBeneficiariesStyles.expandAndCollapseButton}
          onClick={handleExpandClick}
        >
          <ExpandAndCollapseIcon isExpanded={expanded} />
        </Box>
      </Box>

      <Divider sx={enrollmentBeneficiariesStyles.divider} />

      <Box>
        <Collapse
          in={expanded}
          sx={enrollmentBeneficiariesStyles.collapseSection}
          unmountOnExit
        >
          <Box sx={enrollmentBeneficiariesStyles.sectionInstructionRow}>
            <Typography
              sx={enrollmentBeneficiariesStyles.sectionInstructionText}
            >
              Please select your primary beneficiaries.
            </Typography>
            <Tooltip
              title="If adding multiple beneficiaries, allocation must total 100%"
              placement="bottom-end"
              arrow
              enterTouchDelay={100}
            >
              <Box sx={enrollmentBeneficiariesStyles.infoTipContainer}>
                <InfoOutlinedIcon
                  sx={externalEnrollmentSharedStyles.grayInfoTip}
                />
              </Box>
            </Tooltip>
          </Box>

          {/* for future use */}
          {/* <FormGroup sx={{margin: '0px 0px 10px 0px'}}>
                <FormControlLabel
                  control={
                    <Switch
                      // checked={evenSplitPrimary}
                      // onChange={() => setEvenSplitPrimary(!evenSplitPrimary)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Evenly split allocations"
                />
            </FormGroup> */}

          {/* {props?.primaryTotalError &&
                <Box sx={{ display: 'flex', flexDirection: 'row', color: 'red', fontWeight: 700, fontSize: '18px', lineHeight: '25px', marginBottom: '10px', paddingLeft: '0px' }}>
                   Allocations must total 100%
                </Box>
             } */}

          {/* PRIMARIES  */}
          {props.primaryBeneficiaries &&
            props.primaryBeneficiaries?.map((primaryBeneficiary, i) => {
              let correspondingContingent = props?.contingentCheckboxes?.find(
                (contCheck) => {
                  if (contCheck?.id && contCheck.id == primaryBeneficiary.id) {
                    // console.log('have an id', contCheck)
                    return contCheck;
                  } else if (
                    contCheck?.dependentId &&
                    contCheck.dependentId == primaryBeneficiary.dependentId
                  ) {
                    // console.log("have a dep id", contCheck);
                    return contCheck;
                  }
                }
              );
              if (
                !correspondingContingent ||
                correspondingContingent.checked == false
              ) {
                return (
                  <>
                    <Box
                      sx={enrollmentBeneficiariesStyles.singleBennyRow}
                      key={
                        primaryBeneficiary?.id || primaryBeneficiary.dependentId
                      }
                    >
                      <Checkbox
                        checked={
                          props.primaryCheckboxes.find((checkObj) => {
                            if (
                              checkObj.id &&
                              checkObj.id == primaryBeneficiary.id
                            )
                              return checkObj;
                            else if (
                              checkObj.dependentId &&
                              checkObj.dependentId ==
                                primaryBeneficiary.dependentId
                            )
                              return checkObj;
                            else return undefined;
                          })?.checked || false
                        }
                        onClick={(e) => {
                          handleBeneficiaryClick(e, primaryBeneficiary);
                          toggleAutoAllocate(i);
                        }}
                        sx={enrollmentBeneficiariesStyles.checkboxStyle}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            sx={enrollmentBeneficiariesStyles.uncheckedIcon}
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            sx={enrollmentBeneficiariesStyles.checkedIcon}
                          />
                        }
                      />
                      {/* Name and relationship column */}
                      <Box
                        sx={
                          enrollmentBeneficiariesStyles.nameAndRelationshipContainer
                        }
                      >
                        <Box sx={enrollmentBeneficiariesStyles.nameText}>
                          {primaryBeneficiary.firstName}{' '}
                          {primaryBeneficiary.lastName}
                        </Box>

                        <Box
                          sx={enrollmentBeneficiariesStyles.relationshipText}
                        >
                          {props?.relationshipTypes &&
                            (props?.relationshipTypes.find(
                              (relationshipType) =>
                                relationshipType.id ==
                                primaryBeneficiary.relationshipTypeId
                            )?.name ||
                              'Spouse')}
                        </Box>
                      </Box>

                      <Box>
                        <TextField
                          fullWidth
                          label="Allocation"
                          error={props?.primaryTotalError}
                          helperText={
                            props?.primaryTotalError &&
                            props.primaryCheckboxes[i]?.checked
                              ? 'allocations must total 100%'
                              : ''
                          }
                          type="number"
                          autoComplete="off"
                          InputProps={{
                            ...enrollmentBeneficiariesStyles.textFieldInputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            max: '100',
                            min: '0',
                            type: 'number',
                            'data-allocate':
                              props?.enableInputAutoAllocate?.[i],
                          }}
                          onFocus={(e) => e.target.select()}
                          onBlur={(e) => disableAutoAllocate(i)}
                          value={
                            Number(
                              props.primaryCheckboxes.find((checkObj) => {
                                if (checkObj.id)
                                  return checkObj.id == primaryBeneficiary.id;
                                else if (checkObj.dependentId)
                                  return (
                                    checkObj.dependentId ==
                                    primaryBeneficiary.dependentId
                                  );
                              })?.amount
                            ) || ''
                          }
                          onChange={(e) => {
                            manualAllocationHandler(e, primaryBeneficiary, i);
                          }}
                          //disable textfield if checkbox is not checked
                          disabled={
                            !props.primaryCheckboxes.find((checkObj) => {
                              if (
                                checkObj.id &&
                                checkObj.id === primaryBeneficiary.id
                              )
                                return checkObj;
                              else if (
                                checkObj.dependentId &&
                                checkObj.dependentId ===
                                  primaryBeneficiary.dependentId
                              )
                                return checkObj;
                              else return undefined;
                            })?.checked
                          }
                        />
                      </Box>

                      {/* FUTURE edit beneficiary button */}
                      {/* <Box>
                          <Button
                            onClick={() =>
                              props.deleteBeneficiary(
                                primaryBeneficiary?.memberId,
                                primaryBeneficiary?.id,
                                primaryBeneficiary?.dependentId
                              )
                            }
                          >
                            X
                          </Button>
                        </Box>
                        <Box>
                          <IconButton
                            onClick={(e) => {
                              openEditBeneficiary(e, primaryBeneficiary);
                              console.log(props?.editBenny);
                            }}
                            disabled={
                              primaryBeneficiary.relationshipTypeId !== 1
                            }
                            // disabled={!primaryBeneficiary?.id}
                            // sx={{ opacity: !primaryBeneficiary?.id ? 0 : 1}}
                          >
                            <EditIcon />
                          </IconButton>
                        </Box>*/}
                    </Box>

                    {/* EDIT EXISTING BENNY - open textfield to edit selected benny*/}
                    {((props?.editBenny?.id &&
                      props?.editBenny?.id === primaryBeneficiary?.id) ||
                      (props?.editBenny?.dependentId &&
                        props?.editBenny?.dependentId ===
                          primaryBeneficiary?.dependentId)) && (
                      <>
                        {/* First Name */}
                        <Box sx={enrollmentBeneficiariesStyles.inputField}>
                          <TextField
                            fullWidth
                            label="First Name"
                            variant="outlined"
                            value={props?.editBenny?.firstName || ''}
                            onChange={props?.handleInputChange}
                            inputProps={{
                              'data-update': 'firstNamePrimary',
                            }}
                          />
                        </Box>
                        {/* Last Name */}
                        <Box sx={enrollmentBeneficiariesStyles.inputField}>
                          <TextField
                            fullWidth
                            label="Last Name"
                            variant="outlined"
                            value={props?.editBenny?.lastName || ''}
                            onChange={props?.handleInputChange}
                            inputProps={{
                              'data-update': 'lastNamePrimary',
                            }}
                          />
                        </Box>
                        {/* Relationship */}
                        <Box sx={enrollmentBeneficiariesStyles.inputField}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label="Relationship"
                            value={props?.editBenny?.relationshipTypeId || ''}
                            onChange={(e) => {
                              console.log(
                                'editing benny relationshipID...',
                                e.target.value
                              );
                              props?.setEditBenny({
                                ...props?.editBenny,
                                relationshipTypeId: e.target.value,
                              });
                            }}
                          >
                            {props?.relationshipTypeListForEdit &&
                              props?.relationshipTypeListForEdit?.map(
                                (relationshipType) => (
                                  <MenuItem
                                    key={relationshipType.id}
                                    value={relationshipType.id}
                                  >
                                    {relationshipType.name}
                                  </MenuItem>
                                )
                              )}
                          </TextField>
                        </Box>

                        {/*NEED to update this for EDIT function in future DOB */}
                        <Box sx={enrollmentBeneficiariesStyles.inputField}>
                          <LocalizationProvider
                            required={false}
                            dateAdapter={AdapterDateFns}
                          >
                            <DatePicker
                              required={false}
                              // onBlur={(e) => checkError("dob")}
                              disableFuture
                              label="Date of Birth"
                              openTo="year"
                              views={['year', 'month', 'day']}
                              // value={props.primaryBennyAdd.dob || null} //need values for editing existing
                              // onChange={(e) => handleMobileDobChange(e, true)} //need values for editing existing
                              format="MM/dd/yyyy"
                              sx={{ width: '100%' }}
                              slotProps={{
                                openPickerButton: {
                                  sx: { visibility: 'hidden' },
                                }, //hide calendar icon calandar
                              }}
                            />
                          </LocalizationProvider>
                        </Box>

                        {/* Button Container */}
                        <Box sx={enrollmentBeneficiariesStyles.buttonContainer}>
                          <Button
                            variant="outlined"
                            // fullWidth
                            sx={enrollmentBeneficiariesStyles.cancelButton}
                            onClick={() => props?.cancelEditBenny()}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            // fullWidth
                            sx={enrollmentBeneficiariesStyles.saveButton}
                            inputProps={{
                              'data-update': 'updatePrimary',
                            }}
                            onClick={() =>
                              props?.handleEditBeneficiary(
                                props?.editBenny,
                                'isPrimary'
                              )
                            }
                          >
                            Update
                          </Button>
                        </Box>
                      </>
                    )}
                  </>
                );
              }
            })}

          {/* ADD ADDITIONAL PRIMARY BENNY SECTION*/}
          <Box sx={enrollmentBeneficiariesStyles.addAdditionalBennyCheckRow}>
            <Checkbox
              checked={props.primaryBennyAdd}
              onClick={() => {
                props.setPrimaryBennyAdd((state) => !state);
                //if props?.editBenny was true, set to false
                props?.setEditBenny({ id: null });
              }}
              sx={enrollmentBeneficiariesStyles.checkboxStyle}
              icon={
                <CheckBoxOutlineBlankIcon
                  sx={enrollmentBeneficiariesStyles.uncheckedIcon}
                />
              }
              checkedIcon={
                <CheckBoxIcon sx={enrollmentBeneficiariesStyles.checkedIcon} />
              }
            />

            <Typography
              sx={enrollmentBeneficiariesStyles.addAdditionalBennyText}
            >
              Add a primary beneficiary
            </Typography>
          </Box>

          {/* ADD A NEW primary form */}
          {props.primaryBennyAdd && (
            <>
              <Box sx={enrollmentBeneficiariesStyles.inputField}>
                <TextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  value={props.primaryBennyAdd.FirstName || ''}
                  onChange={props?.handleInputChange}
                  inputProps={{
                    'data-update': 'firstNamePrimary',
                  }}
                />
              </Box>

              <Box sx={enrollmentBeneficiariesStyles.inputField}>
                <TextField
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  value={props.primaryBennyAdd.LastName || ''}
                  onChange={props?.handleInputChange}
                  inputProps={{
                    'data-update': 'lastNamePrimary',
                  }}
                />
              </Box>

              <Box sx={enrollmentBeneficiariesStyles.inputField}>
                <TextField
                  fullWidth
                  select
                  // sx={{
                  //   '& fieldset': { borderRadius: '8px' },
                  //   //   '& fieldset > legend': { display: 'none' },
                  //   '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                  //     border: '2px solid #195ffb',
                  //   },
                  // }}
                  variant="outlined"
                  label="Relationship"
                  value={props.primaryBennyAdd.RelationshipTypeId || ''}
                  onChange={(e) =>
                    props.setPrimaryBennyAdd({
                      ...props.primaryBennyAdd,
                      RelationshipTypeId: e.target.value,
                    })
                  }
                >
                  {props?.relationshipTypes &&
                    props?.relationshipTypes?.map((relationshipType) => (
                      <MenuItem
                        key={relationshipType.id}
                        value={relationshipType.id}
                      >
                        {relationshipType.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>

              <Box sx={enrollmentBeneficiariesStyles.inputField}>
                {/* Primary Dob */}
                <Box>
                  <LocalizationProvider
                    required={false}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      required={false}
                      // onBlur={(e) => checkError("dob")}
                      disableFuture
                      label="Date of Birth"
                      openTo="year"
                      views={['year', 'month', 'day']}
                      value={props.primaryBennyAdd.dob || null}
                      onChange={(e) => props?.handleMobileDobChange(e, true)}
                      format="MM/dd/yyyy"
                      sx={{ width: '100%' }}
                      slotProps={{
                        openPickerButton: { sx: { visibility: 'hidden' } }, //hide calendar icon calandar
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>

              <Box sx={enrollmentBeneficiariesStyles.buttonContainer}>
                <Button
                  variant="contained"
                  disabled={props?.disableSavePrimary}
                  sx={enrollmentBeneficiariesStyles.saveButton}
                  onClick={handleAddPrimaryBeneficiary}
                >
                  Save
                </Button>
              </Box>
            </>
          )}
        </Collapse>
      </Box>

      {!expanded && (
        <>
          {props.primaryBeneficiaries &&
            props.primaryBeneficiaries?.map((primaryBeneficiary, i) => {
              let primaryCheck = props.primaryCheckboxes.find(
                (primaryCheck) => primaryCheck.id == primaryBeneficiary.id
              );
              if (primaryCheck?.checked) {
                return (
                  <Box sx={enrollmentBeneficiariesStyles.collapsedRow}>
                    <Typography
                      sx={enrollmentBeneficiariesStyles.collapsedRowText}
                    >
                      {primaryBeneficiary.firstName}{' '}
                      {primaryBeneficiary.lastName}
                    </Typography>

                    <Typography
                      sx={enrollmentBeneficiariesStyles.collapsedRowText}
                    >
                      {primaryCheck.amount}%
                    </Typography>
                  </Box>
                );
              }
            })}
        </>
      )}
    </>
  );
}
