import React, { useEffect, useState } from 'react';

//Assets
import defaultLogo from '../../../assets/images/FullColorOnlyLogo.png';

//Mui Components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//component Styles
const componentStyles = {
  componentContainer: (isLandingPage) => ({
    display: 'flex',
    flexDirection: isLandingPage ? 'column' : 'row',
    justifyContent: isLandingPage ? 'center' : 'flex-start',
    alignItems: isLandingPage ? 'center' : 'flex-start',
    padding: isLandingPage ? '0px' : '0px 0px 5px 0px',
  }),
  logoContainer: (isLandingPage) => ({
    margin: isLandingPage ? '10px auto' : '6px 0px 0px 0px',
    minHeight: '80px',
    minWidth: '80px'
  }),
  image: {
    maxHeight: '86px',
    minHeight: '80px',
    objectFit: 'contain',
  },
  titleContainer: (isLandingPage) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: isLandingPage ? 'center' : 'flex-start',
    maxWidth: isLandingPage ? '320px' : '450px',
    padding: isLandingPage ? '0px' : '0px 0px 0px 20px',
    margin: isLandingPage ? '5px auto 15px' : '0px',
  }),
};

export default function ExternalEnrollmentHeader(props) {
  //style variable: center header for landing page ONLY - value from props
  const isLandingPage = props?.isLandingPage ? props.isLandingPage : false;

  const [selectedEnrollment, setSelectedEnrollment] = useState('');
  useEffect(() => {
    if (props.selectedEnrollment)
      setSelectedEnrollment(props.selectedEnrollment);
  }, [props.selectedEnrollment]);

  //IMAGE FETCH LOGIC

  const [imgFetchAttempts, setImgFetchAttempts] = useState(0);
  const handleLogoError = (e) => {
    // console.log('logo img error',FallbackLogo)
    setImgFetchAttempts((prev) => prev++);
    if (imgFetchAttempts <= 10) e.target.src = defaultLogo;
  };

  return (
    <Box sx={componentStyles.componentContainer(isLandingPage)}>
      <Box sx={componentStyles.logoContainer(isLandingPage)}>
        <img
          alt="logo"
          src={
            props.agentEnroller && props.fullMemberObj?.organizationId > 0
              ? `api/image/organization/${props.fullMemberObj?.organizationId}`
              : '/api/image/organization'
          }
          style={componentStyles.image}
          onError={handleLogoError}
        />
      </Box>
      <Box sx={componentStyles.titleContainer(isLandingPage)}>
        <Typography variant="subtitle1" fontWeight="600">
          {selectedEnrollment?.name || 'Enrollment'}
        </Typography>
      </Box>
    </Box>
  );
}
