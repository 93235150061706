import Settings from '../settings';
// import authService, { authHeader } from "./auth-service"
import { baseService } from './baseService';

class planManagementService extends baseService {
  getAllGroupPlans() {
    const url = Settings.API_URL + 'planbuild/allgroupplans';
    return this.get(url);
  }
  getGroupPlanById(groupPlanId) {
    const url = Settings.API_URL + `planbuild/groupplan/${groupPlanId}`;
    return this.get(url);
  }
  updateGroupPlanDescription(groupPlanId, description) {
    const url =
      Settings.API_URL + `planbuild/update/description/${groupPlanId}`;
    return this.put(url, { description: description });
  }
  updatePlanHelpText(planId, helpText) {
    const url = Settings.API_URL + `planbuild/update/helptext/${planId}`;
    return this.put(url, { helpText: helpText });
  }
  update(type, id, attribute, attributeValue) {
    // type needs to be either 'groupplan' or 'plan', for now
    const url =
      Settings.API_URL + `planbuild/update/${type}/${id}/${attribute}`;
    return this.put(url, { value: attributeValue });
  }
}

const planBuildService = new planManagementService();

export default planBuildService;
